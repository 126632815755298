@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Baloo');
@import url('https://fonts.googleapis.com/css?family=Roboto');

/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #CFD5D1; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #14062b; 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4b09b6; 
}

.accordion li.open i {
    color: #252525;
    left: auto;
    top: 13px;
    margin-left: 10px;
}

#removeCache {
  cursor: pointer;
}
.panel-collapse .panel-body hr {
  border-top: 1px solid #8A8D8B;
}
.ps-image{
  padding-bottom: 20px;
}
/* Download iCONS */
.btn-update { padding:8px; background:#ffffff; margin-right:4px; }
.icon-btn { font-size: 12px; margin: 5px 6px; padding: 0px 10px 0px 0px; border-radius:50px;}

.svg-icon{
  width: 30%;
  height: 30%;
}
#myDateTime {
  color: rgb(20, 6, 43);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  float: right;
  position: relative;
  bottom: 50px;
  z-index: 999;
  text-transform: uppercase;
}
/* Sitemap START*/
.mysitemap .head {
  color: #2781bc;
  font-weight: 900;
  font-size: 1.3em;
  padding-bottom: 10px;
  text-decoration: none;
  text-transform: uppercase;
}
.mysitemap ul {
  padding: 10px;
  border-radius: 10px;
  border-left: 2px solid rgb(20, 6, 43);
}
.ul-mysitemap-tab a{
  font-size: 16px!important;
}
.ul-mysitemap-tab a:link,
.ul-mysitemap-tab a:visited,
.ul-mysitemap-tab a:active {
  color: #BC7127!important;
}
.ul-mysitemap-tab a:hover{
  color: rgb(20, 6, 43)!important;
}
.ul-mysitemap-tab:before {
  content: '';
  display: block;
  height: 0px;
  width: 20px;
  border-bottom: 2px solid rgb(20, 6, 43);
  position: relative;
  left: -12px;
  top: 14px;
}
.li-mysitemap-tab {
  padding-left: 35px;
}
.li-mysitemap-tab a:link,
.li-mysitemap-tab a:visited,
.li-mysitemap-tab a:active {
  color: #7A7F87!important;
}
.li-mysitemap-tab a:hover {
  color: #b63b4d!important;
}
.li-mysitemap-tab:before {
  content: '';
  display: block;
  height: 0px;
  width: 20px;
  border-bottom: 2px solid #7A7F87;
  position: relative;
  left: -18px;
  top: 14px;
}
.mysitemap ul li a{
  padding-left: 15px;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color:rgb(20, 6, 43)
}

/*Floting Button*/
.float-button {
  position: fixed;
  overflow: hidden;
  background-color: rgba(38,101,58, 0.9);
  border: 1px solid white;
  width: 85px;
  height: 85px;
  border-radius: 85px;
  right: 15px;
  bottom: 100px;
  z-index: 99999;
  box-shadow: 0 0px 3px 0 #CCC;
  transition: 0.4s all;
}
.float-button .complete {
  display: flex;
  left: -790px;
  top: -790px;
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: column;
  transition: 0.4s all;
}
.float-button .complete .content {
  display: flex;
  height: 100%;
  flex-direction: row;
  flex: 1;
}
.float-button .complete .content .info {
  /* background: url(../../assets/images/help1.png) center right no-repeat; */
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  padding-top: 160px;
  padding-right: 125px;
  background-position-x: 330px;
  background-position-y: 60px;
}
.float-button .complete .content .info .title {
  font-family: 'Baloo', cursive;
  font-size: 35px;
  color: white;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
}
.float-button .complete .content .info .desc {
  font-family: 'Roboto', sans-serif;
  color: yellow;
  font-size: 25px;
  text-align: left;
  margin-top: 20px;
  line-height: 1.2em;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
}
.float-button .complete .options {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 70px;
  padding: 0 30px;
  background: transparent;
}
.float-button .complete .options .btn {
    padding: 8px 12px;
}
.float-button .complete .options .button {
  cursor: pointer;
  font-size: 14px;
  background: transparent;
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  width: 160px;
  height: 40px;
  transition: 0.3s all;
}
.float-button .complete .options .button:hover {
  background: white;
  color: brown;
}
.float-button .reduced {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 42px;
  opacity: 0.9;
  transition: 0.4s all;
  position: absolute;
  width: 85px;
  right: 0;
  bottom: 0;
}
.float-button:hover,
.float-button.active {
  bottom: 0;
  right: 0;
  width: 560px;
  height: 400px;
  background: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
}
.float-button:hover:before,
.float-button.active:before {
  content: '';
  position: absolute;
  width: 791px;
  height: 791px;
  background-color: rgba(38,101,58, 0.9);
  border-radius: 395.5px;
  border: 1px solid white;
  box-shadow: 0 0px 3px 0 #CCC;
}
.float-button:hover .reduced,
.float-button.active .reduced {
  right: -790px;
  bottom: -790px;
}
.float-button:hover .complete,
.float-button.active .complete {
  left: 0;
  top: 0;
}
/*Floting Button End*/
/*.mysitemap ul li a:hover{
  color: #b63b4d;
}
.mysitemap ul li a:visited{
  text-decoration:none;
  color:inherit;
}*/
/* Sitemap END*/

/*Table*/
.rwd-table {
  margin: 1em 0;
  min-width: 500px;
}
.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.rwd-table th {
  display: none;
}
.rwd-table td {
  display: block;
}
.rwd-table td:first-child {
  padding-top: .5em;
}
.rwd-table td:last-child {
  padding-bottom: .5em;
}
.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 6.5em;
  display: inline-block;
}
@media (min-width: 480px) {
  .rwd-table td:before {
    display: none;
  }
}
.rwd-table th, .rwd-table td {
  text-align: left;
}
@media (min-width: 480px) {
  .rwd-table th, .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child, .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child, .rwd-table td:last-child {
    padding-right: 0;
  }
}
.rwd-table {
  color: #726A6A;
  border-radius: .4em;
  overflow: hidden;
}
.rwd-table tr {
  border-color: #E7E7E7;
}
.rwd-table th, .rwd-table td {
  margin: .5em 1em;
}
@media (min-width: 480px) {
  .rwd-table th, .rwd-table td {
    padding: 1em !important;
  }
}
.rwd-table th, .rwd-table td:before {
  color: #676767;
}

#imgModal {
  /*position: absolute;*/
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(38, 101, 58, 0.6);
  /*text-align: center;*/
  z-index: 999999999999;
}
#imgModal .modal-dialog {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#imgModal .close {
  position: absolute;
  z-index: 99999999999;
  right: -40px;
  top: 10px;
  opacity: 1;
  width: 80px;
  height: 80px;
  /*position: absolute;
  right: -50px;
  top: 0px;
  width: 32px;
  height: 32px;
  opacity: 1;*/
}
#imgModal .close:hover {
  opacity: 0.5;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #FF0000;
}
#imgModal .close:before {
  transform: rotate(45deg);
}
#imgModal .close:after {
  transform: rotate(-45deg);
}



input {
  display: none;
}
input:checked + #jsmuTopBar {
  bottom: -100%;
}

#jsmuTopBar label {
  display: inline-block;
  padding: 0.25em;
  color: #000000;
  background: #FEE46A;
}
#jsmuTopBar label:hover {
  cursor: pointer;
  background: #FAECAD;
}

#jsmuTopBar {
  z-index: 9999;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 2rem;
  text-align: center;
  color: #FFFFFF;
  background-color: #689476;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
}


/* Accordian Submenu Start */
.accordion .link {
  cursor: pointer;
  display: block;
  color: rgb(20, 6, 43);
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.accordion li:last-child .link { border-bottom: 0; }
.accordion li i {
  position: absolute;
  top: 5px;
  left: 12px;
  font-size: 18px;
  color: rgb(20, 6, 43);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.accordion li i.fa-chevron-down {
  right: 7px;
  left: auto;
  font-size: 16px;
}

.accordion li.open .link { color: #b63b4d; }
.accordion li.open i { color: #b63b4d; }
.accordion li.open i.fa-chevron-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

/**
 * Accordion Submenu
 -----------------------------*/
.submenu {
  display: none;
  font-size: 14px;
}
/*.submenu li:first-child .link { padding-top: 15px; }*/

.submenu a {
  display: block;
  text-decoration: none;
  /*color: #d9d9d9;*/
  padding-left: 20px;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.link .submenu li a:hover {
  background: #b63b4d;
  color: #fff;
}
/* Accordian Submenu End */

.btn-semester a {
  /*padding-left: 10px;*/
  padding: 10px 10px 10px 5px; 
  border-right: 1px solid #ddd;
}

.btn-semester:active span,
.btn-semester:hover span {
  text-decoration: none;
  outline: 0 none;
  color: #fff;
}

.btn-semester a:last-child {
  border: none;
}

.btn-semester a span{
  color:#fff;
}

/* Timetable Button */
.btn-group .btn-semester{
  margin: 7px 0px;
  border: 1px solid #ddd;
}
.btn-group .btn-semester:hover span{
  color: #E3D346;
}

.btn-group :hover {
  background: #14062b;
}

/*.panel-body > .btn-group{
  display: inline;
}*/
/*.panel-body > .btn-group>.btn:first-child {padding-left: 20px;}*/
/*.panel-body > .btn-group>.btn:last-child {padding-right: 20px;}*/

.btn-semester{
  position: relative;
  display: inherit;
  color:#fff;
  text-align: center;
  cursor: pointer;
  font-size:18px;
  border-radius: 4px;
  background:rgb(20, 6, 43);
  border: none;
  padding: 10px;
  transition: all 0.3s;
  margin: 7px 20px;
}
.btn-semester span {
  display: inline-block;
  position: relative;
  transition: 0.3s;
}
.btn-semester span:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.3s;
}
.btn-semester:link,
.btn-semester:visited,
.btn-semester:active {
  color: #fff;
}.btn-semester span:hover {
  padding-right: 25px;
  color: #E3D346;
  transition: 0.3s;
}
.btn-semester span:hover:after {
  opacity: 1;
  right: 0;
}


/* start css large Hover dropdown for Programs*/
#rs-header .menu-area .rs-menu ul li .mylg-drop {
  width: 800px!important;
}
/* end css large Hover dropdown for Programs*/

/* Style the tab */
.mylg-tab {
    float: left;
    /*border: 1px solid #FF4646;*/
    background-color: #2C6F41;
}
/* Style the buttons that are used to open the tab content */
.mylg-tab .mylg-tablinks {
    display: block;
    /*background-color: inherit;*/
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: normal;
    padding: 16px 20px;
    /*width: 100%;*/
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
}
.mylg-tab .mylg-tablinks:hover {
    background-color: #0d421e;
}
.mylg-tab .mylg-tablinks.active {
    background-color: #0d421e;
}
.mylg-tabcontent {
    /*text-align: left;*/
    padding: 25px 0px 10px 115px!important;
    /*margin: 10px 15px!important;*/
    /*border: 5px solid #ccc;*/
    color: #fff;
    /*width: 100%;*/
    /*border-left: none;*/
    display: none;
}
.mylg-tabcontent h4{
    color: #BCF0CD;
    font-size: 18px;
    line-height: 1.5px;
    height: 20px;
    border-bottom: 1px solid #eee00c;
    margin: 0px 10px;
    margin-bottom: 15px;
    letter-spacing: 2px;
}
.mylg-tabcontent p{
    padding-left: 12px;
    font-weight: 300;
    line-height: 1.7;
    letter-spacing: 1px
}
.mylg-tabcontent button{
  position: absolute;
  margin-top: 10px;
  margin-left: 12px;
  font-weight: 300;
  letter-spacing: 1px;
}

#fixyfooter {
    position: fixed;
    left: 0;
    bottom: 0;
    color: #fff;
    width: 100%;
    z-index: 99998;
    background-color: #24462F;
    display: flex;
    margin: 0 auto; /*new*/
    overflow: hidden; /*new*/
    white-space: nowrap; /*new*/
}

#fixyfooter div.fade-left {
  background: -moz-linear-gradient( left, rgba(36,70,48,1) 12%, rgba(0,0,0,0) 100% );
  background: -webkit-gradient( linear, left top, right top, color-stop(12%,rgba(36,70,48,1)), color-stop(100%,rgba(0,0,0,0)) );
  background: -webkit-linear-gradient( left, rgba(36,70,48,1) 12%, rgba(0,0,0,0) 100% );
  background: -o-linear-gradient( left, rgba(36,70,48,1) 12%, rgba(0,0,0,0) 100% );
  background: -ms-linear-gradient( left, rgba(36,70,48,1) 12%, rgba(0,0,0,0) 100% );
  background: linear-gradient( to right, rgba(36,70,48,1) 12%, rgba(0,0,0,0) 100% );
  position: absolute;
  left: 0;
  height: 40px;
  width: 10%;
  z-index: 1001;
  left: 23px;
}

#fixyfooter div.fade-right {
  background: -moz-linear-gradient( right, rgba(36,70,48,1) 12%, rgba(0,0,0,0) 100% );
  background: -webkit-gradient( linear, right top, right top, color-stop(12%,rgba(36,70,48,1)), color-stop(100%,rgba(0,0,0,0)) );
  background: -webkit-linear-gradient( right, rgba(36,70,48,1) 12%, rgba(0,0,0,0) 100% );
  background: -o-linear-gradient( right, rgba(36,70,48,1) 12%, rgba(0,0,0,0) 100% );
  background: -ms-linear-gradient( right, rgba(36,70,48,1) 12%, rgba(0,0,0,0) 100% );
  background: linear-gradient( to left, rgba(36,70,48,1) 12%, rgba(0,0,0,0) 100% );
  position: absolute;
  right: 0;
  height: 40px;
  width: 10%;
  z-index: 1001;
}

#fixyfooter marquee span {
  /*font-family: "Poppins";*/
  /*color: #fff;*/
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 1px;
  display: inline-block;
  /*overflow: hidden;*/
  /*white-space: nowrap;*/
  /*box-sizing: border-box;*/
  padding-right: 4rem;
  z-index: 1000;
}

#fixyfooter marquee span a:link, #fixyfooter marquee span a:visited {
  color: #fff;
}

#fixyfooter i {
  z-index: 1002;
  font-size: 18px;
  padding: 5px;
  color: #fff;
  background: #11311B; 
  vertical-align: middle;
  float: right;
  position: absolute;
}
#fixyfooter i:hover {
  color: #E3D346;
  background: #000; 
}
#fixyfooter .date {
  color: #fff;
  background: #9D2424;
  /*border-radius: 8px;*/
  padding: 1px 10px;
  margin: 0 10px;
}
.tabs-left {
  border-bottom: none;
  border-right: 1px solid #ddd;
}
.tabs-left>li {
  float: none;
 margin:0px;

}
.tabs-left>li.active>a,
.tabs-left>li.active>a:hover,
.tabs-left>li.active>a:focus {
  border-bottom-color: #ddd;
  border-right-color: transparent;
  background:rgb(20, 6, 43);
  color: #fff;
  border:none;
  border-radius:0px;
  margin:0px;
}
.nav-tabs>li>a:hover {
    /* margin-right: 2px; */
    line-height: 1.42857143;
    border: 1px solid transparent;
    /* border-radius: 4px 4px 0 0; */
}
.tabs-left>li.active>a::after{
  content: "";
  position: absolute;
  top: 10px;
  right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid rgb(20, 6, 43);
  display: block;
  width: 0;
}

.update-container{
  padding: 10px 0;
}

.update-list {
  overflow-y: auto;
  max-height: 190px;
  min-height: 190px;
  /*display: none;*/
  display: block;
}
.news-update {
  display: flex;
  line-height: 18px;
  padding: 10px 0px 10px 10px;
  margin: 0px 10px 3px 0px;
  font-weight: 600;
  color: #555;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid #DEDCDC;
  /*background: #E9F0EB;*/
  /*border-radius: 10px;*/
  /*-webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;*/
       /*-moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;*/
            /*box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;*/
}
.news-update:hover {
  color: #4e47af;
  background: #eeedf5; /*#F9F2DC*/
  /*border-radius: 10px;*/
  -moz-box-shadow: inset 2px 2px 5px rgba(154, 147, 140, 0.5), 1px 1px 5px rgba(255, 255, 255, 1);
  -webkit-box-shadow: inset 2px 2px 5px rgba(154, 147, 140, 0.5), 1px 1px 5px rgba(255, 255, 255, 1);
  box-shadow: inset 2px 2px 5px rgba(154, 147, 140, 0.5), 1px 1px 5px rgba(255, 255, 255, 1);
}

.update-container h3{
  margin: 10px 0 10px 0!important
}

.update-event_icon {
  width: 2.5em;
  float: left;
  margin-right: 0.75em;
}
.update-event_icon .event_month, .update-event_icon .event_day {
  text-align: center;
}
.update-event_icon .event_month {
  padding: 0.1em;
  /*margin-bottom: 0.15em;*/
  background: #C00000;
  font-size: 0.60em;
  color: white;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
}

#news-v .update-event_icon .event_month{
  background: #C00000;
}
#events-v .update-event_icon .event_month{
  background: #006CC0;
}
#exams-v .update-event_icon .event_month{
  background: #9E00C0;
}
#admissions-v .update-event_icon .event_month{
  background: #C08300;
}
.update-event_icon .event_day {
  border: 1px solid #999;
  background: white;
  color: black;
  font-size: 0.95em;
  font-weight: bold;
  border-bottom-left-radius: 0.1em;
  border-bottom-right-radius: 0.1em;
}

.news-update p {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0!important;
}

.news-update > span {
  display: block;
  font-size: 11px;
  font-weight: normal;
  color: #999;
}

.up-down-btn {
  margin: 0 auto;
  font-size: 12px;
  padding: 5px 10px;
  display: block;
  background-color: rgb(20, 6, 43);
  border: 1px solid transparent;
  color: #ffffff;
  font-weight: 300;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.up-down-btn:hover {
  background-color: #ffffff;
  color: rgb(20, 6, 43);
  border-color: rgb(20, 6, 43);
}

.news-update button {
  margin: 5px 5px;
  font-size: 15px;
  padding: 5px 5px;
  display: block;
  background-color: #14062b;
  border: 1px solid transparent;
  color: #ffffff;
  font-weight: 300;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.news-update button:hover {
  background-color: #7a32ee;
  color: #ffffff;
  border-color: #14062b;
}

.scroll1::-webkit-scrollbar {
  width: 5px;
}
.scroll1::-webkit-scrollbar-track {
  background: #ddd;
}
.scroll1::-webkit-scrollbar-thumb {
  background: #666;
}

/**
* -----------------------------------------------------------------------------
*
**/
/* TABLE OF CONTENT
---------------------------------------------------------
01. General CSS
02. Global Class
03. Header Section
04. Sticky Menu
05. Slider Section
06. About Us
07. Call To Action
08. Services Css
09. Portfolio Css
10. Pricing Tables
11. Team Member
12. Newsletter
13. Testimonial
14. Blog Post
15. Contact Us
16. Footer Css
17. Scroll Up
18. Video Section Index
19. Counter Up
20. Breadcrumbs Css
21. Portfolio Details
22. Blog Details
23. Blog Sidebar
24. Blog Pagination
25. Home Banner
26. Rs Video Area
27. Our History Section
28. Faq Section Start
29. CTA Section Start
30. Responsibiity Section Start
31. Leadership Section
32. Our Products Section
33. Single Product Area
34. Shipping Area Start Here
35. Check Out Css Start Here
36. Why Choose Us
37. How We Work
38. Support Section Css
39. Customer Review Css
40. Preloader Css
--------------------------------------------------------*/
/* -----------------------------------
    01. General CSS
-------------------------------------*/
html,
body {
  font-size: 15px;
  color: #757575;
  font-family: 'Poppins', sans-serif;
  vertical-align: baseline;
  line-height: 25px;
  font-weight: 400;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  margin: 0 0 26px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  color: #212121;
  margin: 0 0 26px;
  font-weight: 600;
}
h1,
h2,
h3 {
  font-weight: 700;
}
h1 {
  font-size: 65px;
}
h2 {
  font-size: 40px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 15px;
}
.uppercase {
  text-transform: uppercase;
}
a {
  color: rgb(20, 6, 43);
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  outline: none !important;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  color: #0d421e;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear {
  clear: both;
}
::-moz-selection {
  background: rgb(20, 6, 43);
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: rgb(20, 6, 43);
  text-shadow: none;
  color: #ffffff;
}
.sec-spacer {
  padding: 94px 0 100px;
}
.gray-color {
  background-color: #f4f7fc;
}
.primary-bg {
  background: rgb(20, 6, 43);
}
.bg-fixed {
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.bg1 {
  /* background-image: url(images/bg/bg1.html); */
  background-size: cover;
  background-attachment: fixed;
}
.bg2 {
  /* background-image: url(images/bg/contact-bg.jpg); */
  background-size: cover;
  background-attachment: fixed;
}
.rs-menu-toggle {
  color: rgb(20, 6, 43);
  text-align: right;
}
/* ------------------------------------
    02. Global Class
---------------------------------------*/
.sec-title {
  margin-bottom: 50px;
}
.sec-title h2 {
  margin-top: 0px;
  font-size: 40px;
  text-transform: uppercase;
}
.sec-title h3 {
  color: #212121;
  margin: 0 0 20px;
  font-size: 30px;
  font-weight: 700;
}
.sec-title.white-text h2,
.sec-title.white-text h3,
.sec-title.white-text p {
  color: #fff;
}
.color-rgba {
  background: rgba(0, 0, 0, 0.2) !important;
}
.readon {
  position: relative;
  display: inline-block;
  padding: 12px 30px;
  line-height: normal;
  background: rgb(20, 6, 43);
  color: #fff;
  transition: all 0.3s ease 0s;
  border-radius: 30px 30px 30px 30px;
}
.readon:hover,
.readon:focus {
  background: #0d421e;
  color: rgba(255, 255, 255, 0.8);
}
.readon.border {
  background: transparent;
  border: 1px solid rgb(20, 6, 43);
  color: rgb(20, 6, 43);
}
.readon.border:hover {
  color: #fff;
  background: rgb(20, 6, 43);
  border: 1px solid #0d421e;
}
.readon.border.white {
  border-color: #fff;
  color: #fff;
}
.readon.border.white:hover {
  color: rgb(20, 6, 43);
  background: #fff;
}
.readon.border.red-color {
  border: 2px solid transparent;
  background: rgb(20, 6, 43);
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 45px;
}
.readon.border.red-color:hover {
  background-color: transparent;
  border: 2px solid rgb(20, 6, 43);
}
.readon.border.transparent {
  border: 2px solid rgb(20, 6, 43);
  background: transparent;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 45px;
}
.readon.border.transparent:hover {
  background-color: rgb(20, 6, 43);
  border: 2px solid transparent;
}
.rs-vertical-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.rs-vertical-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.primary-btn {
  background: rgb(20, 6, 43);
  padding: 8px 25px;
  color: #323232;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
}
.primary-btn:hover {
  color: #fff !important;
}
.p-0 {
  padding: 0;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.pt-10 {
  padding-top: 10px;
}
.p
.pt-45 {
  padding-top: 45px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-94 {
  padding-top: 94px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-94 {
  padding-top: 94px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-190 {
  padding-bottom: 190px;
}
.pr-30 {
  padding-right: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-29 {
  margin-bottom: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.margin-remove {
  margin: 0 !important;
}
.display-table {
  display: table;
  height: 100%;
  width: 100%;
}
.display-table-cell {
  /*padding-top: 130px;*/
  display: table-cell;
  vertical-align: middle;
}
.home2 .display-table {
  margin-top: 0;
}
.white-color {
  color: #fff !important;
}
/* ---------------------------
    03. Header Section
------------------------------*/
#rs-header .mobile-menu {
  display: none;
}
#rs-header .container-fluid {
  padding: 0 60px;
}
#rs-header .toolbar-area {
  background: #0d421e;
  padding: 0;
}
#rs-header .toolbar-area .toolbar-contact ul {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 2.50;
}
#rs-header .toolbar-area .toolbar-contact ul li {
  display: inline-block;
  margin-right: 25px;
  color: #fff;
}
#rs-header .toolbar-area .toolbar-contact ul li i {
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
  color: #fff;
}
#rs-header .toolbar-area .toolbar-contact ul li a {
  transition: all 0.3s ease 0s;
  color: #fff;
}
#rs-header .toolbar-area .toolbar-contact ul li a:hover {
  color: rgba(255, 255, 255, 0.7);
}
#rs-header .toolbar-area .toolbar-sl-share ul {
  text-align: right;
  line-height: 2.50;
}
#rs-header .toolbar-area .toolbar-sl-share ul li.follow {
  color: #fff;
  font-size: 13px;
}
#rs-header .toolbar-area .toolbar-sl-share ul li {
  display: inline-block;
}
#rs-header .toolbar-area .toolbar-sl-share ul li + li {
  margin-left: 10px;
}
#rs-header .toolbar-area .toolbar-sl-share ul li a:before {
  content:'|';
  padding-right: 10px;
  color: #BBBBBB;
}
#rs-header .toolbar-area .toolbar-sl-share ul li a {
  display: block;
  font-size: 13px;
  color: #fff;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}
#rs-header .toolbar-area .toolbar-sl-share ul li a:hover {
  color: rgba(255, 255, 255, 0.7);
}
#rs-header .toolbar-area .toolbar-sl-share ul li a i {
  text-align: center;
  display: inline-block;
}
#rs-header .menu-area {
  background: rgb(20, 6, 43);
}
@media only screen and (max-width: 992px) {
  #rs-header .menu-area {
    background: transparent!important;
  }
}
#rs-header .menu-area .logo-area {
  line-height: 80px;
  height: 90px;
}
#rs-header .menu-area .logo-area a {
  font-weight: 700;
  font-size: 27px;
  color: rgb(20, 6, 43);
  display: block;
  text-transform: lowercase;
  transition: all 0.3s ease 0s;
}
#rs-header .menu-area .logo-area a img {
  display: inline-block;
  vertical-align: text-bottom;
  padding-top: 15px;
  max-width: 350px!important;
  height: auto;
}
@media only screen and (max-width: 992px) {
  #rs-header .menu-area .logo-area a img {
    padding-top: 5px;
  }
  .container {
    padding-bottom: 30px;
  }
}
#rs-header .menu-area .mobile-logo a img {
  display: flex;
  padding-top: 10px;
}
#rs-header .menu-area .rs-menu {
  margin-bottom: 0;
  border: 0;
}
#rs-header .menu-area .rs-menu ul {
  margin: 0;
  padding: 0;
  text-align: right;
}
#rs-header .menu-area .rs-menu ul li {
  height: 90px;
  line-height: 90px;
  display: inline-block;
  transition: all 0.3s ease 0s;
  /* Dropdown Menu area */
}
#rs-header .menu-area .rs-menu ul li + li {
  margin-left: 20px;
}
#rs-header .menu-area .rs-menu ul li.active a {
  color: #0d421e;
}
#rs-header .menu-area .rs-menu ul li > a {
  display: block;
  text-decoration: none;
  color: #444444;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  margin-left: 15px;
  font-size: 16px;
}
#rs-header .menu-area .rs-menu ul li > a i {
  margin-left: 5px;
}
#rs-header .menu-area .rs-menu ul li:hover a {
  color: #0d421e;
}
#rs-header .menu-area .rs-menu ul li:hover a:before,
#rs-header .menu-area .rs-menu ul li:hover a:after {
  width: 20px;
}
#rs-header .menu-area .rs-menu ul li .sub-menu {
  background: rgb(20, 6, 43);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 100%;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  transition: all 0.3s ease 0s;
  width: 220px;
  z-index: 99999 !important;
  text-align: left;
  visibility: hidden;
}
.mylg-tab button, input, select, textarea{
  line-height: normal;
}
#rs-header .menu-area .rs-menu ul li .sub-menu li {
  line-height: normal;
  height: auto;
  display: block;
  border-top: none;
  margin: 0;
  padding: 0;
  border-right: 0px solid transparent;
}
#rs-header .menu-area .rs-menu ul li .sub-menu li:last-child {
  border-bottom: 0;
}
#rs-header .menu-area .rs-menu ul li .sub-menu li a {
  display: block;
  padding: 12px 20px;
  text-transform: none;
  transition: all 0.3s ease 0s;
  color: #fff !important;
  font-weight: 300;
  width: 100%;
  margin: 0;
  font-size: 12px;
}
#rs-header .menu-area .rs-menu ul li .sub-menu li a:before,
#rs-header .menu-area .rs-menu ul li .sub-menu li a:after {
   /*display: none;*/
}
.sub-menu > .menu-item-has-children > a:after{
  content: '\f0da';
   font-family: FontAwesome;
   font-weight: normal;
   font-style: normal;
   margin:0px 0px 0px 10px;
   text-decoration:none;
}
#rs-header .menu-area .rs-menu ul li .sub-menu li:hover {
  background: #0d421e;
}
#rs-header .menu-area .rs-menu ul li .sub-menu li.active a {
  background: #0d421e !important;
}
#rs-header .menu-area .rs-menu ul li .sub-menu li .sub-menu {
  position: absolute;
  top: 0;
  left: 130%;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}
@media only screen and (max-width: 992px) {
  #rs-header .menu-area .rs-menu ul li .sub-menu li .sub-menu {
    top: 40px;
    left: 0!important;
  }
}
#rs-header .menu-area .rs-menu ul li .sub-menu li:hover .sub-menu {
  left: 100%;
  opacity: 1;
  visibility: visible;
}
#rs-header .menu-area .rs-menu ul li:hover ul {
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
}
#rs-header .menu-area .rs-menu ul li.current-menu-item > a {
  color: #E6E45E !important;
}
#rs-header .menu-area .rs-menu .navbar-toggle {
  background-color: rgb(20, 6, 43);
  border-radius: 2px;
  -webkit-transition: .4s ease all;
  transition: .4s ease all;
}
#rs-header .menu-area .rs-menu .navbar-toggle .icon-bar {
  background-color: #fff;
}
#rs-header .menu-area .rs-menu .navbar-toggle:hover {
  opacity: 0.8;
}
#rs-header .menu-area.rs-defult-header .main-menu {
  padding-right: 315px;
}
#rs-header .menu-area.rs-defult-header .rs-menu ul li > a {
  text-transform: capitalize;
  color: #757575;
  font-size: 16px;
  font-weight: 500;
}
#rs-header .menu-area.rs-defult-header .appointment-cart {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
#rs-header .menu-area.rs-defult-header .appointment-cart ul.cart {
  text-align: right;
}
#rs-header .menu-area.rs-defult-header .appointment-cart ul.cart li {
  display: inline-block;
  padding-left: 25px;
  cursor: pointer;
}
#rs-header .menu-area.rs-defult-header .appointment-cart ul.cart li a {
  display: block;
  color: #757575;
}
#rs-header .menu-area.rs-defult-header .appointment-cart ul.cart li a:hover {
  color: rgb(20, 6, 43);
}
#rs-header .menu-area.rs-defult-header .appointment-cart ul.cart li:first-child {
  position: relative;
  padding-left: 0;
}
#rs-header .menu-area.rs-defult-header .appointment-cart ul.cart li:first-child:before {
  position: absolute;
  top: 50%;
  right: -15px;
  content: "";
  height: 15px;
  width: 1px;
  background: #c5c4c0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
#rs-header .menu-area.rs-defult-header .appointment-cart ul.cart li button {
  border: none;
  color: #fff;
  background: rgb(20, 6, 43);
  border-radius: 30px;
  outline: none;
  padding: 8px 20px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
}
#rs-header .menu-area.rs-defult-header .appointment-cart ul.cart li button:hover {
  background: #0d421e;
}
#rs-header .menu-area.rs-defult-header .appointment-cart .search-bar {
  text-align: right;
  position: absolute;
  right: 0;
  bottom: -66px;
  display: none;
}
#rs-header .menu-area.rs-defult-header .appointment-cart .search-bar input {
  border: none;
  padding: 6px 15px;
}
#rs-header .menu-area.rs-defult-header .appointment-cart .search-bar input[type="search"] {
  padding: 7px 14px;
  border: 1px solid rgb(20, 6, 43);
  width: 450px;
}
#rs-header .menu-area.rs-defult-header .appointment-cart .search-bar button {
  background: rgb(20, 6, 43);
  color: #fff;
  position: absolute;
  border: none;
  top: 0;
  right: 0;
  padding: 8px 14px;
  outline: none;
}
#rs-header.transparent-header {
  position: absolute;
  width: 100%;
  z-index: 9999999;
}
#rs-header.transparent-header .menu-area.affix, 
#rs-header.green-header .menu-area.affix {
  background: rgb(20, 6, 43) !important;
}
#rs-header.transparent-header .toolbar-area, 
#rs-header.green-header .toolbar-area {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#rs-header.transparent-header .menu-area,
#rs-header.transparent-header .toolbar-area {
  background: transparent;
}
#rs-header.transparent-header .menu-area .rs-menu ul li > a,
#rs-header.green-header .menu-area .rs-menu ul li > a {
  /*color: #86BA19;*/
  color: #F5F5F5!important;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;
}
#rs-header.transparent-header .menu-sticky {
  box-shadow: none;
}
.defult-home #rs-header {
  position: static;
}
.defult-home .main-content {
  padding-top: 0;
}
.defult-home .owl-carousel .owl-nav.disabled {
  display: block;
}
.nav-menu > .menu-item-has-children > span.rs-menu-parent,
.nav-menu .rs-mega-menu > span.rs-menu-parent {
  background: #0d421e;
  border-left: 1px solid #0d421e;
}
.rs-menu ul ul li {
  border-bottom: 1px solid #0d421e;
}
#rs-header3 .affix {
  background: rgb(20, 6, 43) !important;
  position: fixed !important;
  top: 0px;
  z-index: 999;
  margin: 0 auto !important;
  padding: 0;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-name: sticky-animation;
  animation-name: sticky-animation;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
#rs-header3 .toolbar-area {
  background: rgb(20, 6, 43);
  padding: 0;
  font-size: 14px;
}
#rs-header3 .toolbar-area .welcome-text {
  font-size: 13px;
  color: #fff;
  line-height: 2.50;
}
#rs-header3 .toolbar-area .toolbar-contact ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#rs-header3 .toolbar-area .toolbar-contact ul li {
  display: inline-block;
  margin-right: 25px;
  color: #fff;
}
#rs-header3 .toolbar-area .toolbar-contact ul li i {
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
  color: #fff;
}
#rs-header3 .toolbar-area .toolbar-contact ul li a {
  transition: all 0.3s ease 0s;
  color: #fff;
}
#rs-header3 .toolbar-area .toolbar-contact ul li a:hover {
  color: rgba(255, 255, 255, 0.7);
}
#rs-header3 .toolbar-area .toolbar-sl-share ul {
  text-align: right;
  line-height: 2.50;
}
#rs-header3 .toolbar-area .toolbar-sl-share ul li {
  display: inline-block;
  color: #fff;
  font-size: 13px;
}
#rs-header3 .toolbar-area .toolbar-sl-share ul li + li {
  margin-left: 15px;
}
#rs-header3 .toolbar-area .toolbar-sl-share ul li a {
  display: block;
  color: #fff;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}
#rs-header3 .toolbar-area .toolbar-sl-share ul li a:hover {
  color: rgba(255, 255, 255, 0.7);
}
#rs-header3 .toolbar-area .toolbar-sl-share ul li a i {
  text-align: center;
  display: inline-block;
}
#rs-header3 .toolbar-area .toolbar-sl-share ul li a.quote-button {
  font-size: 14px;
  padding: 6px 10px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
#rs-header3 .toolbar-area .toolbar-sl-share ul li a.quote-button:hover {
  background: #fff;
  color: rgb(20, 6, 43);
}
#rs-header3 .logo-section {
  padding: 25px 0 25px;
}
#rs-header3 .logo-section .contact-info {
  text-align: right;
}
#rs-header3 .logo-section .contact-info li {
  display: inline-block;
  margin: 0 22px 0 0px;
  font-size: 14px;
  transition: .20s;
}
#rs-header3 .logo-section .contact-info li:last-child {
  margin-right: 0;
}
#rs-header3 .logo-section .contact-info li i {
  margin: 0 6px 0 0;
  font-weight: normal;
  height: 30px;
  width: 30px;
  line-height: 27px;
  border: 1px solid #f1f1f1;
  text-align: center;
  font-size: 20px;
  color: rgb(20, 6, 43);
}
#rs-header3 .logo-section .contact-info li a {
  color: #333;
}
#rs-header3 .logo-section .contact-info li:hover i,
#rs-header3 .logo-section .contact-info li:hover a {
  color: #0d421e;
}
#rs-header3 .menu-area {
  background: #ffffff;
}
#rs-header3 .menu-area .rs-menu {
  background: rgb(20, 6, 43);
}
#rs-header3 .menu-area .rs-menu ul {
  text-align: left;
}
#rs-header3 .menu-area .rs-menu ul li a {
  color: #fff;
  padding: 0 20px;
  font-weight: 600;
}
#rs-header3 .menu-area .rs-menu ul li ul li {
  background: rgb(20, 6, 43);
  border-color: #0d421e;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
}
#rs-header3 .menu-area .rs-menu ul li ul li a {
  font-weight: 400;
  padding: 12px 0;
}
#rs-header3 .menu-area .rs-menu ul li ul li:hover {
  background: #0d421e;
}
#rs-header3 .menu-area .rs-menu > ul > li > a {
  line-height: 60px;
  height: 60px;
}
#rs-header3 .menu-area .get-quote {
  background: #0d421e;
  text-align: center;
  transition: 0.4s;
}
#rs-header3 .menu-area .get-quote:hover {
  background: rgb(20, 6, 43);
}
#rs-header3 .menu-area .get-quote a {
  text-align: center;
  color: #fff;
  line-height: 60px;
  height: 60px;
  display: block;
}
#default-header.transparent-header {
  position: absolute;
  width: 100%;
  z-index: 9999;
}
#default-header.transparent-header .menu-sticky {
  background: transparent;
  box-shadow: none;
}
#default-header.transparent-header .menu-sticky li.current-menu-item > a {
  color: rgb(20, 6, 43) !important;
}
#default-header.transparent-header .logo-area {
  padding-top: 0;
  height: 90px;
  line-height: 90px;
}
#default-header .affix {
  background: #000 !important;
  position: fixed !important;
  top: 0px;
  z-index: 999;
  margin: 0 auto !important;
  padding: 0;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-name: sticky-animation;
  animation-name: sticky-animation;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
#default-header .logo-area {
  padding-top: 11px;
}
#default-header .toolbar-area {
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #767c83;
  padding: 0;
  font-size: 14px;
}
#default-header .toolbar-area .welcome-text {
  font-size: 13px;
  color: #fff;
  padding-top: 6px;
}
#default-header .toolbar-area .toolbar-contact {
  padding-top: 6px;
}
#default-header .toolbar-area .toolbar-contact ul {
  margin: 0;
  padding: 0;
  padding-bottom: 4px;
  list-style: none;
}
#default-header .toolbar-area .toolbar-contact ul li {
  display: inline-block;
  margin-right: 25px;
  color: #fff;
}
#default-header .toolbar-area .toolbar-contact ul li .fa-mobile {
  font-size: 18px;
}
#default-header .toolbar-area .toolbar-contact ul li i {
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
  color: #fff;
}
#default-header .toolbar-area .toolbar-contact ul li a {
  transition: all 0.3s ease 0s;
  color: #fff;
}
#default-header .toolbar-area .toolbar-contact ul li a:hover {
  color: rgba(255, 255, 255, 0.7);
}
#default-header .toolbar-area .toolbar-sl-share ul {
  text-align: right;
  padding-top: 5px;
}
#default-header .toolbar-area .toolbar-sl-share ul li {
  display: inline-block;
  color: #fff;
  font-size: 13px;
}
#default-header .toolbar-area .toolbar-sl-share ul li + li {
  margin: 0 0 0 18.9px;
}
#default-header .toolbar-area .toolbar-sl-share ul li a {
  display: block;
  color: #fff;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}
#default-header .toolbar-area .toolbar-sl-share ul li a:hover {
  color: rgba(255, 255, 255, 0.7);
}
#default-header .toolbar-area .toolbar-sl-share ul li a i {
  text-align: center;
  display: inline-block;
}
#default-header .menu-area .rs-menu ul.nav-menu {
  text-align: right;
  /*---drope down---*/
}
#default-header .menu-area .rs-menu ul.nav-menu li {
  padding: 0;
}
#default-header .menu-area .rs-menu ul.nav-menu li a {
  color: #fff;
  padding: 0 15px;
  line-height: 90px;
  font-size: 16px;
  font-weight: 500;
}
#default-header .menu-area .rs-menu ul.nav-menu li a:focus {
  color: rgb(20, 6, 43);
}
#default-header .menu-area .rs-menu ul.nav-menu li:hover a {
  color: rgb(20, 6, 43);
}
#default-header .menu-area .rs-menu ul.nav-menu ul.sub-menu {
  background: rgb(20, 6, 43);
}
#default-header .menu-area .rs-menu ul.nav-menu ul.sub-menu li {
  border-top: 1px solid #0d421e;
  border-bottom: none;
  transition: all .3s;
}
#default-header .menu-area .rs-menu ul.nav-menu ul.sub-menu li a {
  color: #fff;
  line-height: 50px;
  font-weight: 400;
  text-transform: capitalize;
}
#default-header .menu-area .rs-menu ul.nav-menu ul.sub-menu li:first-child {
  border-top: none;
}
#default-header .menu-area .rs-menu ul.nav-menu ul.sub-menu li:hover {
  background: #0d421e;
}
#default-header .menu-area .rs-menu ul.nav-menu ul.sub-menu li.active {
  background: #0d421e;
}
#default-header .menu-area .rs-menu ul.nav-menu > li:last-child > a {
  padding-right: 0;
}
#default-header .menu-area .rs-menu.left-menu ul.nav-menu {
  text-align: right;
}
#default-header .menu-area .rs-menu.left-menu ul.nav-menu > li > a {
  padding-right: 0;
  padding-left: 24px;
}
#default-header .menu-area .rs-menu.right-menu ul.nav-menu {
  text-align: left;
}
#default-header .center-logo,
#default-header .logo {
  line-height: 90px;
}
#default-header .menu-sticky {
  background: #fff;
  margin: 0;
  z-index: 999;
  width: 100%;
  top: 0;
  position: relative;
}
.home1 .main-content {
  padding-top: 0;
}
/*------------------------------------
    04. Sticky Menu
--------------------------------------*/
.affix {
  background: #fff !important;
  position: fixed !important;
  top: 0px;
  z-index: 999;
  margin: 0 auto !important;
  padding: 0;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-name: sticky-animation;
  animation-name: sticky-animation;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.menu-sticky {
  background: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  margin: 0;
  z-index: 999;
  width: 100%;
  top: 0;
  position: relative;
}
@-webkit-keyframes sticky-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes sticky-animation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* ------------------------------------
    05. Slider Section
---------------------------------------*/
.rs-slider {
  position: relative;
  overflow: hidden;
  /*text-align: center;*/
  border-bottom: solid 10px rgb(20, 6, 43);
}
.rs-slider:after {
  background: rgba(0, 0, 0, 0.6);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 5;
}
.rs-slider .sl-subtitle {
  -webkit-animation: fadeInDown 0.5s ease-in-out;
  animation: fadeInDown 0.5s ease-in-out;
  font-size: 50px;
  margin-bottom: 10px;
  font-weight: 600;
}
.rs-slider .sl-title {
  -webkit-animation: fadeInLeft 1s ease-in-out;
  animation: fadeInLeft 1s ease-in-out;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 700;
}
.rs-slider .sl-desc {
  -webkit-animation: fadeInUp 0.8s ease-in-out;
  animation: fadeInUp 0.8s ease-in-out;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 2px;
  font-weight: 500;
}
.rs-slider .slider-botton {
  display: inline-block;
  -webkit-animation: zoomIn 0.8s ease-in-out;
  animation: zoomIn 0.8s ease-in-out;
  margin-top: 30px;
}
.rs-slider .slider-botton ul li {
  display: inline-block;
}
.rs-slider .slider-botton ul li .normal {
  color: red;
}
.rs-slider .slider-botton ul li .readon {
  padding: 16px 45px;
  text-transform: uppercase;
}
.rs-slider .slider-botton ul li .mr-25 {
  border: 2px solid;
}
.rs-slider .slider-botton ul li .white-color {
  padding: 16px 45px;
}
.rs-slider .slider-botton .white-color {
  background: rgb(20, 6, 43);
}
.rs-slider .slider-botton .white-color:hover {
  background: #0d421e;
}
.rs-slider .slider-botton .red-color {
  background: #f00;
}
.rs-slider .slider-botton .red-color:hover {
  background: #f00;
}
.rs-slider .nivo-directionNav > a {
  font-size: 0;
  width: 50px;
  height: 50px;
  border: none;
  background: rgb(20, 6, 43);
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 0;
}
.rs-slider .nivo-directionNav > a:hover {
  background: rgb(20, 6, 43);
}
.rs-slider .nivo-directionNav > a:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 28px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  color: #fff;
}
.rs-slider .nivo-directionNav > a.nivo-prevNav {
  left: 60px;
}
.rs-slider .nivo-directionNav > a.nivo-prevNav:before {
  content: "\f104";
}
.rs-slider .nivo-directionNav > a.nivo-nextNav {
  right: 60px;
}
.rs-slider .nivo-directionNav > a.nivo-nextNav:before {
  content: "\f105";
  margin-left: 2px;
}
.rs-slider .nivoSlider:hover .nivo-directionNav > a {
  opacity: 1;
}
.rs-slider .nivoSlider:hover .nivo-directionNav > a.nivo-prevNav {
  left: 30px;
}
.rs-slider .nivoSlider:hover .nivo-directionNav > a.nivo-nextNav {
  right: 30px;
}
.rs-slider .nivo-controlNav {
  position: absolute;
  bottom: 10px;
  z-index: 11;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index:3000 !important;
  /*display: none !important;*/
}
.rs-slider .nivo-controlNav .nivo-control {
  width: 26px;
  height: 7px;
  border: 1px solid #fff;
  font-size: 0px;
  display: inline-block;
  margin-right: 7px;
  -webkit-transition: .4s ease all;
  transition: .4s ease all;
}
.rs-slider .nivo-controlNav .nivo-control.active {
  background: rgb(20, 6, 43);
  border-color: rgb(20, 6, 43);
}
.home1 .rs-slider .sl-title,
.home2 .rs-slider .sl-title {
  line-height: 45px;
  /*color: #8CC318!important;*/
  text-transform: uppercase;
  font-size: 3em;
  margin: 0.3em 0 0.2em 0;
  letter-spacing: 2px;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgb(12, 12, 12);
  -webkit-text-shadow: 1px 1px 2px rgb(12, 12, 12);
  -o-text-shadow: 1px 1px 2px rgb(12, 12, 12);
  -moz-text-shadow: 1px 1px 2px rgb(12, 12, 12);
}
.home1 .rs-slider .sl-desc span,
.home2 .rs-slider .sl-desc span{
    padding: 0 15px;
    background: rgb(20, 6, 43);
    color: #fff;
    /*font-size: 1.1em;*/
    text-transform: uppercase;
    letter-spacing: 2px;
}

.defult-home .rs-slider .display-table {
  margin-top: 0;
}
.defult-home .rs-slider .nivo-directionNav > a {
  background: transparent;
  border-radius: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 0;
  background: rgb(20, 6, 43);
}
.defult-home .rs-slider .nivo-directionNav > a:hover {
  background: rgb(20, 6, 43);
}
.defult-home .rs-slider .slider-des {
  max-width: 960px;
  margin: 0 auto;
  padding: 25px 0 22px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
}
.defult-home .rs-slider .slider-des h1.sl-title {
  font-size: 60px;
  color: rgb(20, 6, 43);
  font-weight: 700;
  margin: 0 0 10px;
}
.defult-home .rs-slider .slider-des h1.sl-title span {
  display: block;
  font-size: 30px;
  font-weight: 500;
  margin: 0 0 14px;
  line-height: 30px;
}
.defult-home .rs-slider .slider-des .sl-desc {
  font-size: 20px;
  padding: 0 95px;
  color: #cccccc;
  line-height: 28px;
}
.defult-home .rs-slider:after {
  background: rgba(0, 0, 0, 0);
}
.defult-home .rs-slider .readon.border.white-btn {
  background: #fff;
  color: rgb(20, 6, 43);
  border-color: #fff;
}
.defult-home .rs-slider .readon.border.white-btn:hover {
  background: rgb(20, 6, 43);
  color: #fff;
  border-color: rgb(20, 6, 43);
}
/*-----top history------*/
.top-history {
  text-align: center;
}
.top-history .col-md-4 {
  padding: 0;
  margin: 0;
}
.top-history .history-column {
  background: rgb(20, 6, 43);
  box-shadow: inset 0 -4px 20px rgba(0, 0, 0, 0.2);
  transition: .35s;
}
.top-history .history-column:hover {
  box-shadow: inset 1px 0px 20px rgba(0, 0, 0, 0.5);
  transition: .35s;
}
.top-history .history-column i {
  color: #fff;
  font-size: 40px;
  padding-top: 67px;
  clear: both;
  display: block;
}
.top-history .history-column h4 {
  font-size: 20px;
  color: #fff;
  padding-top: 15px;
  margin: 0;
  margin-bottom: 10px;
}
.top-history .history-column p {
  color: #fff;
  padding: 0 15% 63px;
  margin: 0;
}
/* ------------------------------------
    06. About Us
---------------------------------------*/
.rs-about {
  background: #f2f2f2;
}
.rs-about .about-img img {
  box-shadow: 4px 7px 20px 0px rgba(0, 0, 0, 0.1);
  transition: .40s;
}
.rs-about .about-img img:hover {
  box-shadow: 15px 20px 20px 0px rgba(0, 0, 0, 0.2);
}
.rs-about .about-img.pr-md-50 {
  padding-right: 15px;
}
.rs-about .pr-md-65 {
  padding-right: 30px;
}
.rs-about .sec-title {
  margin: 0;
}
.rs-about .sec-title h4 {
  color: #101010;
  margin: 0 0 15px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
}
.rs-about .signature-text .sign-title {
  font-size: 20px;
  color: #14062b;
  margin: 0;
}
.rs-about .signature-text .sign-subtitle {
  color: #40256b;
  font-style: italic;
  font-size: 13px;
}
.rs-about .signature-text img {
  max-width: 200px;
  display: block;
}
.rs-about-3 {
  background: #fff;
}
.rs-about-3 .sec-title {
  margin-bottom: 0;
}
.rs-about-3 .sec-title h2 {
  font-size: 40px;
  font-weight: 600;
  color: #101010;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.rs-about-3 .sec-title h3 {
  margin-bottom: 20px;
}
.rs-about-3 .sec-title h4 {
  color: #101010;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 15px;
}
.rs-about-3 .sec-title .about-desc {
  font-size: 15px;
  line-height: 26px;
}
.rs-about-3 .pr-md-65 {
  padding-right: 30px;
}
.rs-about-3 .about-desc {
  margin-bottom: 42px;
}
/*-----#panel according-------*/
.panel-group .panel {
  border: none;
  box-shadow: none;
  background: unset;
  margin-bottom: 19px;
}
.panel-group .panel .panel-heading {
  background: #f0f0f0;
  height: 50px;
}
.panel-group .panel .panel-heading .panel-title {
  color: #505050;
  font-size: 15px;
  line-height: 31px;
  position: relative;
  margin-left: 70px;
}
.panel-group .panel .panel-heading .panel-title a {
  display: block;
}
.panel-group .panel .panel-heading .panel-title a:hover {
  color: unset;
}
.panel-group .panel .panel-heading .panel-title a:after {
  position: absolute;
  font-family: FontAwesome;
  content: "\f067";
  left: -86px;
  color: #fff;
  top: -10px;
  background: rgb(20, 6, 43);
  padding: 9.4px 30.2px;
}
.panel-group .panel-collapse .panel-body {
  /*border-top: none !important;*/
  width: 100%;
  /*margin-top: 12px;*/
  background: #f0f0f0;
}
.panel-group .panel-collapse .panel-body p {
  margin: 0;
}
.achievements-area {
  padding-bottom: 82px;
}
/* ----------------------------------
    07. Call To Action
-------------------------------------*/
.rs-cta {
  padding: 65px 0;
  position: relative;
}
.rs-cta .cta-content h3 {
  font-size: 30px;
  line-height: 100%;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 10px;
  text-align: left;
}
.rs-cta .cta-content p {
  font-size: 15px;
  color: #fff;
  margin: 0;
}
.rs-cta .cta-button {
  margin-top: 6px;
}
.rs-cta .cta-button a {
  background: transparent;
  color: ;
  border: 2px solid #fff;
  padding-left: 55px;
  font-weight: 600;
  padding-right: 55px;
}
.rs-cta .cta-button a:hover {
  background: #0d421e !important;
  color: #fff !important;
  border: 2px solid #fff;
}
/* ----------------------------------
    08. Services Css
-------------------------------------*/
.rs-services-3 .rs-vertical-middle .service-title {
  margin-top: -30px;
}
.rs-services-3.services-pages .common {
  background: #f4f7fc;
}
.rs-services-3 .service-title h3 {
  line-height: 40px;
  margin: 0 0 12px;
}
.rs-services-3 .service-title p {
  margin: 0 0 34px;
}
.rs-services-3.gray-color .common {
  background: #fff;
}
.rs-services-3 .common {
  background: #f4f7fc;
  padding: 33px 11px 21px 22px;
  border-bottom: 4px solid rgb(20, 6, 43);
  margin-bottom: 30px;
  transition: .20s;
}
.rs-services-3 .common .col-sm-9 {
  padding-left: 0;
}
.rs-services-3 .common .icon {
  font-size: 40px;
  text-align: center;
  color: rgb(20, 6, 43);
}
.rs-services-3 .common .text h4 {
  margin: 0 auto;
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 20px;
}
.rs-services-3 .common .text p {
  margin-bottom: 0;
}
.rs-services-3 .common:hover {
  background: rgb(20, 6, 43);
}
.rs-services-3 .common:hover .icon {
  color: #fff;
}
.rs-services-3 .common:hover h4,
.rs-services-3 .common:hover p {
  color: #fff;
}
.rs-services {
  padding-bottom: 50px;
}
.rs-services.pb-70 {
  padding-bottom: 70px;
}
.rs-services .item-thumb {
  width: 100%;
  margin-top: -12px;
}
.rs-services .item-thumb .owl-dot {
  width: 33.333%;
  display: inline-block;
  justify-content: center;
  padding: 10px;
  float: left;
  cursor: pointer;
}
.rs-services .item-thumb.item-thumb2 {
  margin: 0;
  overflow: hidden;
}
.rs-services .item-thumb.item-thumb2 .owl-dot {
  width: 50%;
  float: left;
  padding: 0 15px;
  margin-bottom: 30px;
  z-index: 0;
}
.rs-services .item-thumb.item-thumb2 .owl-dot:before {
  display: none;
}
.rs-services .item-thumb.item-thumb2 .owl-dot span {
  box-shadow: none;
  opacity: 1 !important;
}
.rs-services .item-thumb.item-thumb2 .owl-dot span i {
  font-size: 36px;
}
.rs-services .item-thumb.item-thumb2 .owl-dot span i:before {
  line-height: 40px;
}
.rs-services .item-thumb.item-thumb2 .owl-dot h5.overlay-feature-title {
  font-size: 18px;
  line-height: normal;
  position: static;
  -webkit-transform: translate(0);
  transform: translate(0);
  z-index: 99;
  width: 100%;
  transition: all 500ms ease;
  background: #fff;
  opacity: 1;
  margin: 0;
  width: 100%;
  padding: 35.5px 0;
}
.rs-services .item-thumb.item-thumb2 .owl-dot h5.overlay-feature-title a {
  color: #000;
}
.rs-services .item-thumb.item-thumb2 .owl-dot h5.overlay-feature-title a:hover {
  color: #0d421e;
}
.rs-services .item-thumb.item-thumb2 .owl-dot:hover span,
.rs-services .item-thumb.item-thumb2 .owl-dot.active span,
.rs-services .item-thumb.item-thumb2 .owl-dot:hover h5.overlay-feature-title a,
.rs-services .item-thumb.item-thumb2 .owl-dot.active h5.overlay-feature-title a {
  color: #fff;
}
.rs-services .item-thumb.item-thumb2 .owl-dot:hover h5.overlay-feature-title,
.rs-services .item-thumb.item-thumb2 .owl-dot.active h5.overlay-feature-title {
  background: rgb(20, 6, 43);
}
.rs-services .device-lg .item-thumb .owl-dot,
.rs-services .device-sm .item-thumb .owl-dot {
  height: 150px;
}
.rs-services .device-xs .item-thumb .owl-dot {
  width: 50%;
  padding: 10px;
  height: 100px;
}
.rs-services .item-thumb .owl-dot span {
  position: relative;
  display: block;
  background-size: cover;
  background-position: center center;
  width: auto;
  height: 100%;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
.rs-services .item-thumb .owl-dot.active span,
.rs-services .item-thumb .owl-dot:hover span {
  opacity: .7;
}
.rs-services .menu-carousel .heading-block h4 {
  margin: 4px 0 12px;
  font-size: 20px;
  line-height: 20px;
}
.rs-services .cl-ft-item {
  background-color: #FFF;
}
.rs-services .menu-carousel .heading-block h4 a {
  font-weight: 600;
  color: #212121;
  transition: .20s;
}
.rs-services .menu-carousel .heading-block h4 a:hover {
  color: rgb(20, 6, 43);
}
.rs-services .menu-carousel .feature-content {
  position: relative;
  padding: 30px;
  text-align: left;
}
.rs-services .menu-carousel .heading-block p {
  margin-bottom: 0;
}
.rs-services .menu-carousel .heading-block .readon {
  margin-top: 20px;
}
.rs-services .menu-carousel .heading-block .food-info {
  color: #BBB;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 14px;
}
.rs-services .food-price {
  position: absolute;
  top: 40px;
  right: 50px;
  font-size: 20px;
  letter-spacing: -1px;
  font-weight: 500;
}
.rs-services .menu-carousel .more-link {
  margin-top: 35px;
  border-bottom: 1px solid #333 !important;
}
.rs-services .more-link {
  font-style: normal;
  border-bottom: 0;
  color: #333;
  margin-top: 10px;
  font-size: 14px;
}
.rs-services .team-title h4 {
  font-size: 15px;
}
.rs-services .team-title span {
  font-style: normal;
  margin-bottom: 15px;
  color: #AAA !important;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.rs-services .entry {
  padding: 0;
  margin: 0;
  border-bottom: 0;
  -webkit-box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.08);
}
.rs-services .entry-title {
  background-color: #FFF;
  padding: 15px;
}
.rs-services .entry-title h2 {
  text-transform: none;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
}
.rs-services .entry-meta {
  margin: 8px 0 0;
}
.rs-services .ipost .entry-meta li a {
  font-size: 11px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #999;
}
.rs-services .entry-meta li:before {
  content: '\00b7';
  opacity: 1;
}
.rs-services .service-button {
  margin: 80px 0 80px 15px;
}
.rs-services .device-sm .service-button,
.rs-services .device-xs .service-button {
  margin: 30px 0 0;
}
.rs-services .clfeatures .cl-ft-item img {
  display: block;
  margin: 0;
}
.rs-services .item-thumb .owl-dot {
  position: relative;
}
.rs-services .item-thumb .owl-dot:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 999;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background: rgba(0, 0, 0, 0.8);
  transition: all 500ms ease;
  opacity: 0;
}
.rs-services h5.overlay-feature-title {
  font-size: 16px;
  line-height: 140%;
  font-weight: normal;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
  text-align: center;
  width: calc(80%);
  transition: all 500ms ease;
  opacity: 0;
}
.rs-services h5.overlay-feature-title a {
  color: #fff;
  pointer-events: none;
}
.rs-services .item-thumb .owl-dot:hover:before {
  opacity: 1;
}
.rs-services .item-thumb .owl-dot:hover h5.overlay-feature-title {
  opacity: 1;
}
@media (min-width: 992px) {
  .rs-services #header,
  .rs-services #header-wrap {
    z-index: 200;
    background-color: transparent;
    background-repeat: repeat-x;
    background-position: center bottom;
    background-size: auto 100%;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
  }
  .rs-services #primary-menu ul li > a {
    padding-top: 32px;
    padding-bottom: 10px;
  }
  .rs-services #header.sticky-header #header-wrap {
    background-color: transparent;
    box-shadow: none;
  }
  .rs-services .slider-caption h2 {
    font-size: 76px;
  }
  .rs-services .food-price {
    font-size: 24px;
    top: 50px;
    right: 60px;
  }
}
.rs-services .services-wrap .services-item {
  position: relative;
  padding: 0px 25px 65px;
  z-index: 10;
}
.rs-services .services-wrap .services-item:after {
  content: '';
  position: absolute;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  background: #fff;
  transition: all 0.3s ease 0s;
  top: 50%;
  transform: scale(1, 0.9) translateY(-50%);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  opacity: 0;
}
.rs-services .services-wrap .services-item:hover .services-desc:after {
  background: rgb(20, 6, 43);
  width: 100px;
}
.rs-services .services-wrap .services-item:hover:after {
  opacity: 1;
  transform: scale(1, 1) translateY(-50%);
}
.rs-services .services-wrap .services-icon {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: rgb(20, 6, 43);
}
.rs-services .services-wrap .services-title {
  font-size: 20px;
  margin-top: 35px;
  margin-bottom: 25px;
}
.rs-services .services-wrap .services-desc {
  position: relative;
}
.rs-services .services-wrap .services-desc:after {
  content: '';
  position: absolute;
  left: 0;
  height: 2px;
  width: 50px;
  bottom: -45px;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}
.rs-services .services-wrap .services-desc p {
  margin-bottom: 55px;
}
.service-details-section .services-left-sidebar {
  overflow: hidden;
}
.service-details-section .services-left-sidebar .serives-content-one {
  overflow: hidden;
}
.service-details-section .services-left-sidebar .serives-content-one .single-services-details {
  float: left;
  width: 45%;
  text-align: justify;
}
.service-details-section .services-left-sidebar .serives-content-one .single-service-img {
  width: 50%;
  float: right;
}
.service-details-section .services-left-sidebar .serives-content-one .single-service-img img {
  margin-top: 2px;
}
.service-details-section .services-left-sidebar .serives-content-one .banner-top {
  padding-bottom: 30px;
}
.service-details-section .services-left-sidebar .serives-content-one .banner-top img {
  width: 100%;
}
.service-details-section .services-left-sidebar .two-content-box ul,
.service-details-section .services-left-sidebar .sinlge-middle-content ul {
  list-style: none;
  margin: 0px 0px 20px;
  padding: 0px;
}
.service-details-section .services-left-sidebar .two-content-box ul li,
.service-details-section .services-left-sidebar .sinlge-middle-content ul li {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: normal;
  line-height: 30px;
  color: #000000;
  position: relative;
  padding-left: 15px;
}
.service-details-section .services-left-sidebar .two-content-box ul li:before,
.service-details-section .services-left-sidebar .sinlge-middle-content ul li:before {
  content: "\f105";
  font-family: FontAwesome;
  margin-right: 10px;
  color: rgb(20, 6, 43);
  font-weight: bold;
  position: absolute;
  left: 0;
}
.service-details-section .services-left-sidebar .two-content-box h4 {
  color: #101010;
  margin: 20px 0 25px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
}
.service-details-section .services-left-sidebar .two-content-box p {
  margin: 0;
}
.service-details-section .services-sidebar .service-cats-area {
  border: 2px solid #d2dde2;
  padding: 15px;
  padding-bottom: 25px;
}
.service-details-section .services-sidebar .service-cats-area h4 {
  padding: 15px 15px;
  margin: -15px -15px 20px;
  background-color: #303745;
  color: #fff;
  text-transform: uppercase;
}
.service-details-section .services-sidebar .service-cats-area ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.service-details-section .services-sidebar .service-cats-area ul li {
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 3px 0px;
  font-size: 15px;
  font-weight: normal;
  line-height: 40px;
  color: #303745;
}
.service-details-section .services-sidebar .service-cats-area ul li a {
  color: #303745;
}
.service-details-section .services-sidebar .service-cats-area ul li a:hover {
  color: #0d421e;
}
.service-details-section .services-sidebar .service-cats-area ul li:before {
  content: "\f105";
  font-family: FontAwesome;
  margin-right: 10px;
  font-weight: bold;
}
.service-details-section .services-sidebar .service-cats-contact {
  color: #fff;
  padding: 20px;
  background-color: #303745;
  margin-top: 40px;
}
.service-details-section .services-sidebar .service-cats-contact .service-cats-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 1px solid #2c2c2c;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  padding: 15px 20px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form {
  margin: 0 auto;
  text-align: center;
  display: block;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form .contact-button {
  width: 100%;
  outline: none;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  height: 56px;
  line-height: 52px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form .contact-button:hover {
  background-color: #fff;
  color: #303745;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form {
  margin: 0 auto;
  text-align: center;
  display: block;
  max-width: 570px;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form .form-field {
  margin-bottom: 14px;
  position: relative;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form .form-field span.sscf-error {
  left: 0px;
  top: 0px;
  padding: 0 20px;
  text-align: left;
  margin: 0px;
  height: 50px;
  line-height: 48px;
  font-size: 14px;
  color: red;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form .form-field .sscf-error {
  font-family: Helvetica, Arial, sans-serif;
  display: block;
  position: absolute;
  line-height: 0;
  margin: 0;
  height: 0;
  padding: 0;
  font-size: 10px;
  z-index: 90;
  margin-top: 12px;
  right: 15px;
  color: #dc0000;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form .form-field input {
  width: 100%;
  height: 50px;
  padding: 5px 40px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
  font-size: 15px;
  min-width: inherit;
  text-transform: capitalize;
  background: #ffffff;
  color: #757575;
  border-radius: 0;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form .form-field input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #757575;
  opacity: 1;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form .form-field input::-moz-placeholder {
  /* Firefox 19+ */
  color: #757575;
  opacity: 1;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form .form-field input:-ms-input-placeholder {
  /* IE 10+ */
  color: #757575;
  opacity: 1;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form .form-field input:-moz-placeholder {
  /* Firefox 18- */
  color: #757575;
  opacity: 1;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form .form-field textarea {
  width: 100%;
  padding: 10px 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  box-shadow: none;
  font-size: 15px;
  border-radius: 0;
  color: #757575;
}
.service-details-section .services-sidebar .service-cats-contact .contact-form form .form-field i {
  color: #555555;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 15px;
}
/* ----------------------------------
    09. Portfolio Css
-------------------------------------*/
.rs-portfolio .sec-title {
  margin-bottom: 45px;
}
.rs-portfolio .portfolio-filter {
  margin-bottom: 45px;
  text-align: center;
}
.rs-portfolio .portfolio-filter button {
  background: transparent;
  display: inline-block;
  border: 0;
  outline: none;
  font-weight: 400;
  transition: all 0.3s ease 0s;
  padding: 8px 18px !important;
  min-width: 150px !important;
  border: 2px solid rgb(20, 6, 43) !important;
  border-radius: 30px !important;
  background: transparent !important;
  margin-right: 15px;
  font-size: 15px;
}
.rs-portfolio .portfolio-filter button:hover {
  background: rgb(20, 6, 43) !important;
  border: 2px solid transparent !important;
  color: #fff;
}
.rs-portfolio .portfolio-filter .active {
  background: rgb(20, 6, 43) !important;
  border: 2px solid transparent !important;
  color: #fff;
}
.rs-portfolio .portfolio-item {
  position: relative;
  overflow: hidden;
}
.rs-portfolio .portfolio-item .portfolio-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 10;
  padding: 10px;
  opacity: 0;
  -webkit-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}
.rs-portfolio .portfolio-item .portfolio-content:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
  -webkit-transition: .25s opacity ease;
  transition: .25s opacity ease;
}
.rs-portfolio .portfolio-item .portfolio-content .border-overly:after {
  border-right: 5px solid rgb(20, 6, 43);
  border-left: 5px solid rgb(20, 6, 43);
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
}
.rs-portfolio .portfolio-item .portfolio-content .border-overly:after,
.rs-portfolio .portfolio-item .portfolio-content .border-overly:before {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  z-index: 1;
}
.rs-portfolio .portfolio-item .portfolio-content .border-overly:before {
  border-top: 5px solid rgb(20, 6, 43);
  border-bottom: 5px solid rgb(20, 6, 43);
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}
.rs-portfolio .portfolio-item .portfolio-content .display-table {
  margin-top: 2px !important;
}
.rs-portfolio .portfolio-item .portfolio-content .display-table .display-table-cell {
  vertical-align: middle;
  position: relative;
  z-index: 999;
}
.rs-portfolio .portfolio-item .portfolio-content .display-table .display-table-cell .image-popup {
  margin-right: 6px;
}
.rs-portfolio .portfolio-item .portfolio-img img {
  width: 100%;
}
.rs-portfolio .portfolio-item .p-zoom {
  display: inline-block;
}
.rs-portfolio .portfolio-item .p-zoom i {
  width: 48px;
  height: 48px;
  line-height: 44px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 20px;
  color: #ffffff;
  padding: 0px;
  transition: .20s;
}
.rs-portfolio .portfolio-item .p-zoom i:hover {
  border: 2px solid rgb(20, 6, 43);
  color: rgb(20, 6, 43);
}
.rs-portfolio .portfolio-item .btn-view {
  display: inline-block;
}
.rs-portfolio .portfolio-item .btn-view i {
  width: 48px;
  height: 48px;
  line-height: 44px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 22px;
  color: #ffffff;
  padding: 0px;
  transition: .20s;
}
.rs-portfolio .portfolio-item .btn-view i:hover {
  border: 2px solid rgb(20, 6, 43);
  color: rgb(20, 6, 43);
}
.rs-portfolio .portfolio-item .p-title {
  font-size: 20px;
  line-height: normal;
  margin-top: 25px;
  color: #fff;
  margin-bottom: 0;
}
.rs-portfolio .portfolio-item .p-title a {
  color: #fff;
}
.rs-portfolio .portfolio-item .p-title a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.rs-portfolio .portfolio-item .p-desc {
  font-size: 15px;
  color: #fff;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 10px;
}
.rs-portfolio .portfolio-item .portfolio-content:hover {
  opacity: 1;
  top: 0;
}
.rs-portfolio .portfolio-item:hover .portfolio-content .border-overly:after,
.rs-portfolio .portfolio-item:hover .portfolio-content .border-overly:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.rs-portfolio.defutl-style {
  padding-top: 50px;
}
.rs-portfolio.defutl-style .owl-carousel .owl-nav {
  top: -294px;
}
.rs-portfolio.defutl-style .portfolio-title {
  margin-bottom: 10px;
}
.rs-portfolio.defutl-style .readon {
  padding-top: 8px;
  padding-bottom: 8px;
}
.rs-portfolio.defutl-style .portfolio-item .portfolio-content .display-table {
  margin-top: 0 !important;
}
.rs-portfolio.defutl-style .portfolio-item .portfolio-content .p-title {
  margin: 5px 0 15px;
  line-height: 26px;
  font-weight: 600;
}
.rs-portfolio.defutl-style .portfolio-item .portfolio-content .p-title a {
  color: #E3D346;
}
.rs-portfolio.defutl-style .portfolio-item .portfolio-content .p-desc {
  margin-top: 0;
}
.rs-portfolio.defutl-style .owl-carousel .owl-nav {
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
}
.rs-portfolio.defutl-style .owl-carousel .owl-nav .owl-prev,
.rs-portfolio.defutl-style .owl-carousel .owl-nav .owl-next {
  position: absolute;
  right: 0;
  left: auto;
  top: -172px;
}
.rs-portfolio.defutl-style .owl-carousel .owl-nav .owl-prev {
  right: 40px;
}
/* ----------------------------------
    10. Pricing Tables
-------------------------------------*/
.rs-pricing .selected .pricing-head {
  background: rgb(20, 6, 43);
}
.rs-pricing .selected .pricing-head .name {
  color: #fff !important;
}
.rs-pricing .selected .pricing-head .price .value {
  color: #fff !important;
}
.rs-pricing .selected .pricing-head .price .value sup {
  color: #fff !important;
}
.rs-pricing .selected .pricing-head .price .duration {
  color: #fff !important;
}
.rs-pricing .pricing-plan {
  background: #fff;
  border: 1px solid #e1e1e1;
  text-align: center;
  margin-bottom: 30px;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
  position: relative;
  z-index: 10;
}
.rs-pricing .pricing-plan:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 2px solid rgb(20, 6, 43);
  opacity: 0;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}
.rs-pricing .pricing-plan .pricing-head {
  position: relative;
  padding: 35px;
  z-index: 10;
  background: #f0f0f0;
}
.rs-pricing .pricing-plan .pricing-head .name {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
  color: #212121;
  transition: all 0.3s ease 0s;
}
.rs-pricing .pricing-plan .pricing-head .value {
  display: inline-block;
  color: #212121;
  font-size: 52px;
  font-weight: 300;
  transition: all 0.3s ease 0s;
}
.rs-pricing .pricing-plan .pricing-head .value sup {
  color: #757575;
  font-size: 22px;
  top: -22px;
  transition: all 0.3s ease 0s;
}
.rs-pricing .pricing-plan .pricing-head .duration {
  font-size: 15px;
  transition: all 0.3s ease 0s;
}
.rs-pricing .pricing-plan .pricing-body ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 15px;
  padding-top: 20px;
}
.rs-pricing .pricing-plan .pricing-body ul li {
  padding: 7px 10px;
}
.rs-pricing .pricing-plan .pricing-footer {
  position: relative;
  z-index: 100;
  padding: 20px 40px 40px;
}
.rs-pricing .pricing-plan .pricing-footer .p-button {
  display: inline-block;
  min-width: 165px;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  background-color: rgb(20, 6, 43);
  color: #fff;
  border-radius: 4px;
  transition: all 0.3s ease 0s;
  border-radius: 30px 30px 30px 30px;
}
.rs-pricing .pricing-plan .pricing-footer .p-button:hover {
  background: #0d421e;
}
.rs-pricing .pricing-plan:hover .pricing-head,
.rs-pricing .pricing-plan.featured .pricing-head {
  background: rgb(20, 6, 43);
}
.rs-pricing .pricing-plan:hover .name,
.rs-pricing .pricing-plan.featured .name,
.rs-pricing .pricing-plan:hover .duration,
.rs-pricing .pricing-plan.featured .duration {
  color: #fff;
}
.rs-pricing .pricing-plan:hover .value,
.rs-pricing .pricing-plan.featured .value {
  color: #fff;
}
.rs-pricing .pricing-plan:hover .value sup,
.rs-pricing .pricing-plan.featured .value sup {
  color: #fff;
}
.rs-pricing .pricing-plan:hover:before,
.rs-pricing .pricing-plan.featured:before {
  opacity: 1;
}
#rs-pricing2 .pricing-plan {
  border: 1px solid #e1e1e1;
  text-align: center;
  margin-bottom: 30px;
  -webkit-transition: .3s ease all;
  -moz-transition: .3s ease all;
  -o-transition: .3s ease all;
  -ms-transition: .3s ease all;
  transition: .3s ease all;
  position: relative;
  z-index: 10;
}
#rs-pricing2 .pricing-plan:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 2px solid #0d421e;
  opacity: 0;
  -webkit-transition: .3s ease all;
  -moz-transition: .3s ease all;
  -o-transition: .3s ease all;
  -ms-transition: .3s ease all;
}
#rs-pricing2 .pricing-plan.featured-plan .pricing-head,
#rs-pricing2 .pricing-plan:hover .pricing-head {
  background: #0d421e;
}
#rs-pricing2 .pricing-plan.featured-plan .pricing-head .name,
#rs-pricing2 .pricing-plan:hover .pricing-head .name,
#rs-pricing2 .pricing-plan.featured-plan .pricing-head .value,
#rs-pricing2 .pricing-plan:hover .pricing-head .value,
#rs-pricing2 .pricing-plan.featured-plan .pricing-head sup,
#rs-pricing2 .pricing-plan:hover .pricing-head sup,
#rs-pricing2 .pricing-plan.featured-plan .pricing-head .duration,
#rs-pricing2 .pricing-plan:hover .pricing-head .duration {
  color: #fff !important;
}
#rs-pricing2 .pricing-plan .pricing-head {
  position: relative;
  padding: 35px;
  z-index: 10;
  background: #f0f0f0;
  -webkit-transition: .3s ease all;
  -moz-transition: .3s ease all;
  -o-transition: .3s ease all;
  -ms-transition: .3s ease all;
}
#rs-pricing2 .pricing-plan .pricing-head .name {
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
#rs-pricing2 .pricing-plan .pricing-head .value {
  display: inline-block;
  font-size: 60px;
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
#rs-pricing2 .pricing-plan .pricing-head .value sup {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  top: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
#rs-pricing2 .pricing-plan .pricing-body ul {
  list-style-type: none;
  padding-left: 0;
  padding-top: 20px;
  font-size: 15px;
  color: #757575;
}
#rs-pricing2 .pricing-plan .pricing-body ul li {
  padding: 7px 15px;
}
#rs-pricing2 .pricing-plan .pricing-footer {
  position: relative;
  z-index: 100;
  padding: 25px 40px 40px;
}
#rs-pricing2 .pricing-plan .pricing-footer a {
  font-weight: 500;
  padding: 17px 28px;
}
#rs-pricing2 .pricing-plan:hover .name,
#rs-pricing2 .pricing-plan.featured .name,
#rs-pricing2 .pricing-plan:hover .duration,
#rs-pricing2 .pricing-plan.featured .duration {
  color: rgb(20, 6, 43);
}
#rs-pricing2 .pricing-plan:hover .value,
#rs-pricing2 .pricing-plan.featured .value {
  color: rgb(20, 6, 43);
}
#rs-pricing2 .pricing-plan:hover .value sup,
#rs-pricing2 .pricing-plan.featured .value sup {
  color: rgb(20, 6, 43);
}
#rs-pricing2 .pricing-plan:hover:before,
#rs-pricing2 .pricing-plan.featured:before {
  opacity: 1;
}
#rs-pricing2 .pricing-plan:hover .p-button,
#rs-pricing2 .pricing-plan.featured .p-button {
  background-color: rgb(20, 6, 43);
}
.rs-pricing4 .sec-title {
  overflow: hidden;
  display: block;
}
.rs-pricing4 .pricing-plan {
  padding: 20px 20px 1px;
  margin: 0;
  padding-bottom: 25px;
  text-align: center;
  box-shadow: 0 6px 25px 7px #e5e5e5;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 0;
  border: 1px solid rgb(20, 6, 43);
  transition: .20s;
}
.rs-pricing4 .pricing-plan .top {
  padding-bottom: 30px;
}
.rs-pricing4 .pricing-plan .top .cl-header h4 {
  background: rgb(20, 6, 43);
  font-size: 18px;
  font-weight: 500;
  margin: -20px -20px 64px;
  padding: 20px;
  text-transform: uppercase;
  line-height: 20px;
  color: #fff;
  transition: .20s;
}
.rs-pricing4 .pricing-plan .top .cl-header h4 span.dolar {
  border-radius: 100px;
  box-shadow: 0 5px 20px #dddddd inset, 0 3px 0 #999999 inset;
  display: block;
  font: bold 25px/100px Georgia, serif;
  height: 100px;
  margin: 20px auto -65px;
  width: 100px;
  border: 5px solid #fff;
  background: #fff;
  color: rgb(20, 6, 43) !important;
  line-height: 90px !important;
  transition: .40s;
}
.rs-pricing4 .pricing-plan .top a.btn-table {
  border: 2px solid rgb(20, 6, 43);
  border-radius: 30px;
  transition: all 0.2s linear;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 44px;
  text-transform: uppercase;
  color: #666;
  transition: .30s;
}
.rs-pricing4 .pricing-plan .top a.btn-table:hover {
  color: #fff;
  background: #0d421e !important;
  border: 2px solid #0d421e;
}
.rs-pricing4 .pricing-plan .bottom ul {
  text-align: center;
}
.rs-pricing4 .pricing-plan .bottom ul li {
  border-top: 1px solid #ddd;
  color: #333;
  padding: 10px 0;
  line-height: 24px;
  font-size: 15px;
  font-weight: 400;
}
.rs-pricing4 .pricing-plan:hover {
  border: 1px solid #0d421e;
}
.rs-pricing4 .pricing-plan:hover .top .cl-header h4 {
  background: #0d421e;
}
.rs-pricing4 .pricing-plan:hover .top .cl-header h4 span {
  transform: rotate(360deg);
}
.rs-pricing4 .pricing-plan:hover .top a.btn-table {
  background: rgb(20, 6, 43);
  color: #fff;
}
.rs-pricing4 .action {
  height: 490px;
  margin-top: -23px;
  border: 1px solid #0d421e;
}
.rs-pricing4 .action .top .cl-header h4 {
  background: #0d421e;
  padding-top: 43px;
}
.rs-pricing4 .action .top a.btn-table {
  background: rgb(20, 6, 43);
  color: #fff;
  bordr: 2px solid rgb(20, 6, 43);
}
/* -----------------------
    11. Team Member
--------------------------*/
.rs-team.team-pages .team-item {
  margin-bottom: 20px;
}
.rs-team .team-item .grid-style-1 .team-item-wrap {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.rs-team .team-item .grid-style-1 .team-item-wrap a {
  text-decoration: none;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-img {
  position: relative;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-img img {
  width: 100%;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-img .normal-text {
  position: absolute;
  left: 0px;
  bottom: 10px;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-img .normal-text .team-name {
  background: rgb(20, 6, 43);
  margin-bottom: 2px;
  padding: 10px 20px;
  display: block;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-img .normal-text .team-name a {
  color: #fff;
  position: relative;
  margin-bottom: 6px;
  padding-left: 9px;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  z-index: 111;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-img .normal-text .team-name a:hover {
  color: rgb(20, 6, 43);
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-img .normal-text .subtitle {
  background: #333;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  padding: 10px 27px;
  display: block;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  top: 10%;
  left: 0;
  text-align: center;
  z-index: 11;
  padding: 30px;
  opacity: 0;
  -webkit-transition: .3s;
  transition: .3s;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
  transition: .5s;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table {
  display: table;
  height: 100%;
  width: 100%;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table .display-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table .display-table-cell .team-name a {
  margin-bottom: 6px;
  font-size: 20px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table .display-table-cell .team-name a:hover {
  color: rgb(20, 6, 43);
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table .display-table-cell .team-title {
  font-size: 14px;
  padding-bottom: 12px;
  border: none;
  color: #E6E45E;
  position: relative;
  z-index: 111;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table .display-table-cell .team-social {
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 111;
  opacity: 0;
  margin-top: 0;
  transition: .5s;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table .display-table-cell .team-social a {
  border: 1px solid #363636;
  color: rgb(20, 6, 43);
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table .display-table-cell .team-social .social-icon {
  display: inline-block;
  background-color: rgb(20, 6, 43);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 6px;
  border-radius: unset;
  border: 1px solid rgb(20, 6, 43);
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table .display-table-cell .team-social .social-icon:hover {
  background-color: #fff;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table .display-table-cell .team-social .social-icon i {
  font-size: 14px;
  color: #000;
}
.rs-team .team-item .grid-style-1 .team-item-wrap .team-content .display-table .display-table-cell p {
  margin: 15px 0 8px;
}
.rs-team .team-item .grid-style-1 .team-item-wrap:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 32px;
  width: 45px;
  background: transparent;
  border-top: 3px solid;
  border-top-color: rgb(20, 6, 43);
  border-left: 18px solid;
  border-left-color: rgb(20, 6, 43);
  transition: all 0.2s;
  z-index: 11;
}
.rs-team .team-item .grid-style-1 .team-item-wrap:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  height: 32px;
  width: 45px;
  background: transparent;
  border-right: 19px solid;
  border-right-color: rgb(20, 6, 43);
  border-bottom: 3px solid;
  border-bottom-color: rgb(20, 6, 43);
  transition: 0.5s;
}
.rs-team .team-item .grid-style-1 .team-item-wrap:before,
.rs-team .team-item .grid-style-1 .team-item-wrap:after {
  border-color: rgb(20, 6, 43);
}
.rs-team .team-item .grid-style-1 .team-item-wrap:hover:before {
  width: 0px;
  height: 0px;
  border-bottom: 50px solid transparent;
  border-left: 50px solid transparent;
  border-right: none;
  z-index: 100;
  top: -3px;
  border-left: 52px solid rgb(20, 6, 43);
}
.rs-team .team-item .grid-style-1 .team-item-wrap:hover:after {
  width: 0px;
  height: 0px;
  border-top: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: none;
  z-index: 100;
  border-right: 50px solid rgb(20, 6, 43);
}
.rs-team .team-item .grid-style-1 .team-item-wrap:hover .team-content {
  opacity: 1;
  top: 0;
}
.rs-team .team-item .grid-style-1 .team-item-wrap:hover .team-content .display-table .display-table-cell .team-social {
  opacity: 1;
}
.rs-team .team-item .grid-style-1 .team-item-wrap:hover .team-img .normal-text {
  opacity: 0;
  visibility: hidden;
}
.rs-team .team-item {
  position: relative;
  overflow: hidden;
  transition: .25s;
}
.rs-team .team-item .team-img img {
  width: 100%;
}
.rs-team .team-item .team-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 10%;
  left: 0;
  text-align: center;
  z-index: 10;
  padding: 30px;
  opacity: 0;
  -webkit-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}
.rs-team .team-item .team-content:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
  -webkit-transition: .25s opacity ease;
  transition: .25s opacity ease;
}
.rs-team .team-item .team-content .display-table {
  margin-top: 0;
}
.rs-team .team-item .team-icon {
  font-size: 50px;
  color: rgb(20, 6, 43);
  margin-bottom: 20px;
}
.rs-team .team-item .team-name {
  margin-bottom: 6px;
  font-size: 20px;
  color: #fff;
}
.rs-team .team-item .team-title {
  font-size: 12px;
  color: rgb(20, 6, 43);
}
.rs-team .team-item .team-desc {
  color: #fff;
  margin-bottom: 0;
  margin-top: 12px;
}
.rs-team .team-item .team-social {
  text-align: center;
  z-index: 1;
}
.rs-team .team-item .team-social .social-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: rgb(20, 6, 43);
  margin-right: 6px;
  border-radius: 50%;
  margin-top: 20px;
}
.rs-team .team-item .team-social .social-icon i {
  font-size: 14px;
  color: #fff;
}
.rs-team .team-item .team-social .social-icon:hover {
  background-color: #0d421e;
}
.rs-team .team-item:hover .team-content {
  opacity: 1;
  top: 0;
}
.owl-carousel {
  padding-top: 0;
}
.owl-carousel .owl-nav {
  position: absolute;
  right: 0;
  top: -80px;
}
.owl-carousel .owl-nav [class*="owl-"] {
  font-size: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: #131313;
  border: 1px solid #d7d7d7;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease 0s;
}
.owl-carousel .owl-nav [class*="owl-"]:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.owl-carousel .owl-nav [class*="owl-"].owl-prev {
  border-color: transparent;
  background: rgb(20, 6, 43);
  color: #fff;
  left: -2px;
}
.owl-carousel .owl-nav [class*="owl-"].owl-prev:before {
  content: "\f104";
}
.owl-carousel .owl-nav [class*="owl-"].owl-next {
  border-color: transparent;
  background: rgb(20, 6, 43);
  color: #fff;
}
.owl-carousel .owl-nav [class*="owl-"].owl-next:before {
  content: "\f105";
  margin-left: 2px;
}
.owl-carousel .owl-nav [class*="owl-"]:hover {
  background-color: #0d421e;
}
.rs-team-details {
  padding: 100px 0 90px;
}
.rs-team-details .figure img {
  margin-bottom: 30px;
}
.rs-team-details .clear-it {
  clear: both;
  padding-top: 20px;
}
.rs-team-details .description .member-title h3 {
  margin-bottom: 0;
  font-size: 30px;
}
.rs-team-details .description .member-title span {
  display: block;
  margin: 6px 0 4px;
}
.rs-team-details .description .social-share .social-icons {
  padding: 10px 0;
}
.rs-team-details .description .social-share .social-icons a i {
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  margin-right: 7px;
  text-align: center;
  width: 32px;
  background: #0d421e;
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.rs-team-details .description .social-share .social-icons a:hover i {
  background: rgb(20, 6, 43);
}
.rs-team-details .contact-details {
  margin-bottom: 30px;
}
.rs-team-details .contact-details ul li {
  padding: 6px 0;
}
.rs-team-details .contact-details ul li a {
  color: #757575;
}
.rs-team-details .contact-details ul li a:hover {
  color: rgb(20, 6, 43);
}
.rs-team-details .contact-details ul li i {
  border: 1px solid #0d421e;
  color: #0d421e;
  font-size: 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  display: inline-block;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.rs-team-details .contact-details ul li span {
  margin-left: 10px;
}
.rs-team-details .contact-details ul li:hover i {
  background: #0d421e;
  color: #fff;
}
.rs-team-details .cl-skill-bar .skillbar {
  background: #eeeeee;
  display: inline-block;
  height: 30px;
  margin: 5px 0;
  position: relative;
  width: 100%;
}
.rs-team-details .cl-skill-bar .skillbar .skillbar-title {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  height: 30px;
  left: 0;
  line-height: 30px;
  padding: 0 20px;
  position: absolute;
  top: 0;
  width: 160px;
  background: rgb(20, 6, 43);
}
.rs-team-details .cl-skill-bar .skillbar .skillbar-bar {
  background: #0d421e;
  border-radius: 3px;
  display: inline-block;
  height: 30px;
  width: 0;
  margin-bottom: 0;
}
.rs-team-details .cl-skill-bar .skillbar .skill-bar-percent {
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 10px;
  top: 0;
}
.rs-defult-team .team-item {
  text-align: center;
  z-index: 1;
  position: relative;
}
.rs-defult-team .team-item .team-img {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.rs-defult-team .team-item .team-img img {
  width: 100%;
  height: auto;
}
.rs-defult-team .team-item:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease 0s;
}
.rs-defult-team .team-item .team-social {
  list-style: none;
  transition: all 0.5s ease 0s;
}
.rs-defult-team .team-item .team-social li {
  display: inline-block;
}
.rs-defult-team .team-item .team-social li a {
  display: block;
  color: rgb(20, 6, 43);
  padding: 0 10px;
}
.rs-defult-team .team-item .team-social li a:hover {
  color: #fff;
}
.rs-defult-team .team-item .team-social,
.rs-defult-team .team-item p.des {
  display: none;
}
.rs-defult-team .team-item p.des {
  color: #fff;
  margin: 5px 0 15px;
}
.rs-defult-team .team-item .team-content {
  width: 100%;
  height: 85px;
  border-radius: 50%;
  border: 2px solid rgb(20, 6, 43);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.5s ease 0s;
  background: #fff;
}
.rs-defult-team .team-item .team-info {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  z-index: 11;
  padding: 0 5px;
}
.rs-defult-team .team-item .title {
  font-size: 20px;
  font-weight: 600;
  color: #323232;
  margin: 0 0 5px 0;
  transition: all 0.5s ease 0s;
}
.rs-defult-team .team-item .post {
  display: block;
}
.rs-defult-team .team-item:hover .team-social,
.rs-defult-team .team-item:hover .team-img:after {
  opacity: 1;
}
.rs-defult-team .team-item:hover .title {
  color: rgb(20, 6, 43);
}
.rs-defult-team .team-item:hover .title:hover {
  color: #fff;
}
.rs-defult-team .team-item:hover .team-content {
  height: 100%;
  background: rgba(29, 36, 42, 0.8);
  border: none;
}
.rs-defult-team .team-item:hover .team-content span.post {
  color: #ccc;
}
.rs-defult-team .team-item:hover .team-content .team-social,
.rs-defult-team .team-item:hover .team-content p.des {
  display: block;
}
.rs-team4 .team-item {
  background: #fff;
  border: 1px solid #e0dfdf;
  text-align: center;
  transition: all 0.25s;
  padding: 40px 20px;
}
.rs-team4 .team-item .team-img img {
  width: 120px;
  height: auto;
  margin: 0 auto 35px;
  border-radius: 50%;
  transition: all 0.25s;
}
.rs-team4 .team-item .team-content .display-table .display-table-cell h4.team-name {
  font-size: 20px;
  margin: 0 0 10px;
  color: rgb(20, 6, 43);
  transition: 0.25s;
}
.rs-team4 .team-item .team-content .display-table .display-table-cell h4.team-name:hover {
  color: #0d421e;
}
.rs-team4 .team-item .team-content .display-table .display-table-cell span.team-title {
  color: #0d421e;
  margin-bottom: 15px;
  display: block;
  text-transform: uppercase;
}
.rs-team4 .team-item .team-content .display-table .display-table-cell p {
  margin: 0 0 26px;
}
.rs-team4 .team-item .team-social a {
  color: rgb(20, 6, 43);
  font-size: 18px;
  padding: 0 10px;
  transition: .20s;
}
.rs-team4 .team-item .team-social a:hover {
  color: #0d421e;
}
.rs-team4 .team-item:hover {
  border: 1px solid rgb(20, 6, 43);
}
.rs-team4 .team-item:hover .team-img img {
  transform: scale(1.1);
}
/* -----------------------
    12. Newsletter
--------------------------*/
.rs-newsletter {
  text-align: center;
  background-attachment: fixed;
  transition: all 0.3s ease 0s;
  background-size: cover;
  background-position: center center;
  padding: 100px 0;
  line-height: 0;
  position: relative;
}
.rs-newsletter .overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}
.rs-newsletter .news-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 22px;
}
.rs-newsletter .news-note {
  font-size: 18px;
  margin-bottom: 30px;
  line-height: normal;
}
.rs-newsletter .news-form {
  max-width: 710px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.rs-newsletter .news-form .form-input {
  width: 100%;
  height: 50px;
  padding: 5px 20px;
  background: transparent;
  outline: none;
  color: #fff;
  border: 1px solid rgb(20, 6, 43);
}
.rs-newsletter .news-form .form-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}
.rs-newsletter .news-form .form-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
.rs-newsletter .news-form .form-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}
.rs-newsletter .news-form .form-input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}
.rs-newsletter .news-form .form-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  min-width: 200px;
  background-color: rgb(20, 6, 43);
  border: 0;
  color: #fff;
  transition: all 0.3s ease 0s;
}
.rs-newsletter .news-form .form-button:hover {
  background-color: #0d421e;
}
/* -----------------------
    13. Testimonial
--------------------------*/
.rs-testimonial .testi-filter.nav-tabs {
  border: 0;
}
.rs-testimonial .testi-filter .testi-img {
  position: relative;
}
.rs-testimonial .testi-filter .testi-img:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: 0.4s all ease-out;
  transition: 0.4s all ease-out;
}
.rs-testimonial .testi-filter .testi-img img {
  width: 100%;
}
.rs-testimonial .testi-filter li {
  margin-bottom: 0;
}
.rs-testimonial .testi-filter li a {
  padding: 0;
  border: 0 !important;
  background: transparent !important;
  text-align: center;
}
.rs-testimonial .testi-filter li.active .testi-img:before,
.rs-testimonial .testi-filter li:hover .testi-img:before {
  width: 80%;
  height: 80%;
  opacity: 0;
}
.rs-testimonial .testi-name {
  margin-bottom: 3px;
  font-size: 16px;
  line-height: normal;
  margin-top: 20px;
}
.rs-testimonial .testi-title {
  font-size: 13px;
  line-height: normal;
  color: #757575;
}
.rs-testimonial .testi-content {
  position: relative;
  margin-top: 35px;
  padding: 30px 45px;
  text-align: justify;
  background-color: rgb(20, 6, 43);
  color: #fff;
}
.rs-testimonial .testi-content:before {
  content: '';
  position: absolute;
  top: -18px;
  height: 38px;
  width: 38px;
  background: transparent;
  border: 1px solid rgb(20, 6, 43);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
}
.rs-testimonial .testi-content p {
  margin-bottom: 0;
  position: relative;
  padding: 0 55px;
}
.rs-testimonial .testi-content p:before,
.rs-testimonial .testi-content p:after {
  position: absolute;
  font-family: FontAwesome;
  font-size: 26px;
  color: #fff;
  opacity: 0.3;
}
.rs-testimonial .testi-content p:before {
  content: "\f10d";
  left: 0;
}
.rs-testimonial .testi-content p:after {
  content: "\f10e";
  right: 0;
}
.rs-testimonial .tab-pane:nth-child(1) .testi-content:before {
  margin-left: -30px;
  left: 17%;
}
.rs-testimonial .tab-pane:nth-child(2) .testi-content:before {
  margin-left: -19px;
  left: 50%;
}
.rs-testimonial .tab-pane:nth-child(3) .testi-content:before {
  margin-right: -30px;
  right: 17%;
}
.rs-testimonial .testi-carousel .slick-slide {
  outline: none;
}
.rs-testimonial .testi-carousel .slick-arrow {
  position: absolute;
  top: -70px;
  right: 0;
  font-size: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: #131313;
  background: transparent;
  border: 1px solid #d7d7d7;
  display: inline-block;
  transition: all .3s ease 0s;
  outline: none;
}
.rs-testimonial .testi-carousel .slick-arrow:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 26px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.rs-testimonial .testi-carousel .slick-arrow.slick-prev {
  right: 38px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  background: rgb(20, 6, 43);
  color: #fff;
  border: transparent;
}
.rs-testimonial .testi-carousel .slick-arrow.slick-prev:before {
  content: "\f104";
}
.rs-testimonial .testi-carousel .slick-arrow.slick-next {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  background: rgb(20, 6, 43);
  color: #fff;
  border: transparent;
}
.rs-testimonial .testi-carousel .slick-arrow.slick-next:before {
  content: "\f105";
  margin-left: 2px;
}
.rs-testimonial .testi-carousel .slick-arrow:hover {
  background: #0d421e;
}
.rs-testimonial-section .testi-item {
  margin-top: 50px;
  padding: 30px 0 68px;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
}
.rs-testimonial-section .testi-item:first-child {
  margin-top: 0;
  padding-top: 0;
}
.rs-testimonial-section .testi-item .item-img {
  width: 120px;
  height: 120px;
  margin: 0 auto 15px;
}
.rs-testimonial-section .testi-item .item-img img {
  border-radius: 100%;
  transition: .25s;
}
.rs-testimonial-section .testi-item:hover .item-img img {
  transform: scale(0.95);
}
.rs-testimonial-section .testi-item .item-detail p {
  margin-bottom: 0px;
  font-style: italic;
  text-align: justify;
  font-weight: normal;
}
.rs-testimonial-section .testi-item .item-detail p i {
  margin-right: 5px;
  font-size: 16px;
  color: #0d421e;
}
.rs-testimonial-section .testi-item .title {
  font-weight: 500;
  font-size: 16px;
  margin-top: 15px;
  color: #0d421e;
  position: relative;
}
.rs-testimonial-section .testi-item .title:after {
  position: absolute;
  content: '';
  /*background: @hover-color;*/
  width: 0px;
  height: 1px;
  left: 0;
  bottom: 0px;
  transition: .40s;
}
.rs-testimonial-section .testi-item .designation {
  font-weight: 600;
}
.rs-testimonial-section .testi-item .stars ul {
  list-style: none;
}
.rs-testimonial-section .testi-item .stars ul li {
  display: inline;
  color: #0d421e;
}
.rs-testimonial-section .testi-item:hover .title:after {
  width: 92px;
}
.pagination-style {
  margin-top: 20px;
  text-align: center;
  overflow: hidden;
}
.pagination-style ul li {
  display: inline-block;
  margin-right: 10px;
}
.pagination-style ul li a {
  padding: 8px 13px;
  border: 1px solid rgb(20, 6, 43);
  display: block;
}
.pagination-style ul li a:hover {
  background: rgb(20, 6, 43);
  color: #fff;
}
.pagination-style ul .active a {
  color: #fff;
  background: rgb(20, 6, 43);
}
.rs-home-testimonial .sec-title h3 {
  margin: 0 0 12px;
}
.rs-home-testimonial .testimonial {
  margin: 0 15px;
}
.rs-home-testimonial .testimonial .img-text {
  width: 100%;
  height: 50px;
  position: relative;
  border-radius: 100px 0 0 0;
}
.rs-home-testimonial .testimonial .img-text .image {
  position: absolute;
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: 0;
  display: inline-block;
  left: 50%;
  bottom: -40px;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.rs-home-testimonial .testimonial .img-text .image img {
  width: 100%;
  height: 100%;
}
.rs-home-testimonial .testimonial .description {
  width: 100%;
  line-height: 26px;
  padding: 50px 20px 10px;
  background-color: rgb(20, 6, 43);
  margin: 0;
}
.rs-home-testimonial .testimonial .description p {
  margin: 0;
  color: #fff;
  padding: 10px 0 20px;
}
.rs-home-testimonial .testimonial .client-info {
  text-align: center;
  background-color: #f2f2f2;
  padding-bottom: 35px;
  border-radius: 0 0 100px 0;
}
.rs-home-testimonial .testimonial .client-info .testimonial-title {
  display: inline-block;
  text-transform: capitalize;
  margin-top: 35px;
}
.rs-home-testimonial .testimonial .client-info .testimonial-title span {
  color: rgb(20, 6, 43);
  display: block;
  font-weight: 600;
  margin-bottom: 0;
}
.rs-home-testimonial .testimonial .client-info .testimonial-title small {
  display: block;
  font-size: 14px;
  margin: 0;
  color: #101010;
}
ul.rs-testimonial-page .testi-item:last-child {
  border: none !important;
}
.pagination-testimonial .pagination-area {
  padding-top: 0;
}
#rs-testimonial2 {
  padding: 100px 0 100px;
  background: #f2f2f2;
}
#rs-testimonial2 .sec-title {
  text-align: center;
}
#rs-testimonial2 .sec-title h2 {
  font-size: 40px;
  font-weight: 700;
}
#rs-testimonial2 .sec-title p {
  font-weight: 300;
  font-size: 18px;
  color: #505050;
  margin-bottom: 50px;
}
#rs-testimonial2 .slick-slide {
  margin: 0px 45px;
  transition: all ease-in-out 0.3s;
  opacity: .2;
  width: 100px;
  position: relative;
}
#rs-testimonial2 .slick-slide:focus {
  outline: none;
}
#rs-testimonial2 .container {
  padding: 0;
  position: relative;
}
#rs-testimonial2 .container .rs-tst-content {
  padding: 0 70px !important;
}
#rs-testimonial2 .rs-tst-slider {
  max-width: 860px;
  margin: 0 auto;
  padding: 0;
}
#rs-testimonial2 .rs-tst-slider .slick-list {
  padding-top: 50px !important;
}
#rs-testimonial2 .slick-slide img {
  width: 100%;
  border-radius: 50%;
}
#rs-testimonial2 .slick-slide img:focus {
  outline: none;
}
#rs-testimonial2 .slick-prev {
  border: none;
  box-shadow: none;
  background: none;
  font-size: 0;
  color: #fff;
  position: absolute;
  top: 35%;
  mleft: -180px;
  transform: translateX(-50%);
}
#rs-testimonial2 .slick-prev:before {
  color: #fff !important;
}
#rs-testimonial2 .slick-next {
  border: none;
  box-shadow: none;
  background: none;
  font-size: 0;
  color: #fff;
  position: absolute;
  top: 35%;
  right: -180px;
  transform: translateX(-50%);
}
#rs-testimonial2 .slick-next:before {
  color: #fff !important;
}
#rs-testimonial2 .slick-prev:before,
#rs-testimonial2 .slick-next:before {
  color: black;
  font-size: 36px;
  color: #101010;
}
#rs-testimonial2 .slick-prev:before:focus,
#rs-testimonial2 .slick-next:before:focus {
  outline: none;
}
#rs-testimonial2 .slick-prev:hover:before,
#rs-testimonial2 .slick-next:hover:before {
  color: #0d421e;
}
#rs-testimonial2 .slick-prev:before {
  font-family: FontAwesome;
  content: "\f104";
}
#rs-testimonial2 .slick-prev:before:focus {
  outline: none;
}
#rs-testimonial2 .slick-next:before {
  font-family: FontAwesome;
  content: "\f105";
}
#rs-testimonial2 .slick-next:before:focus {
  outline: none;
}
#rs-testimonial2 .slick-active {
  opacity: .5;
}
#rs-testimonial2 .slick-active:focus {
  outline: none;
}
#rs-testimonial2 .slick-current {
  opacity: 1;
  transition: all 0.3s ease 0s;
}
#rs-testimonial2 .slick-current:focus {
  outline: none;
}
#rs-testimonial2 .slick-current img {
  transform: scale(1.7);
  transition: all 0.3s ease 0s;
}
#rs-testimonial2 .slick-current img:focus {
  outline: none;
}
#rs-testimonial2 .rs-tst-content {
  margin: 0px -340%;
  padding-bottom: 15px;
  text-align: center;
  display: none;
}
#rs-testimonial2 .rs-tst-content:focus {
  outline: none;
}
#rs-testimonial2 .slick-current .rs-tst-content {
  display: block;
}
#rs-testimonial2 .slick-current .rs-tst-content:focus {
  outline: none;
}
#rs-testimonial2 .rs-tst-title {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: #101010;
  font-weight: 600;
  margin-top: 58px;
}
#rs-testimonial2 .rs-tst-title:focus {
  outline: none;
}
#rs-testimonial2 .rs-tst-designation {
  font-family: 'Poppins', sans-serif;
  color: #0d421e;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 7px;
}
#rs-testimonial2 .rs-tst-designation:focus {
  outline: none;
}
#rs-testimonial2 .rs-tst-details p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #444444;
}
#rs-testimonial2 .rs-tst-details p:focus {
  outline: none;
}
#rs-testimonial2.skew-testimonial2 {
  background: #fff;
}
#rs-testimonial2.skew-testimonial2 .slick-prev {
  border: none;
  box-shadow: none;
  background: none;
  font-size: 0;
  color: #fff;
  position: absolute;
  top: 35%;
  left: -180px;
  transform: translateX(-50%);
}
#rs-testimonial2.skew-testimonial2 .slick-prev:before {
  color: rgb(20, 6, 43) !important;
}
#rs-testimonial2.skew-testimonial2 .slick-next {
  border: none;
  box-shadow: none;
  background: none;
  font-size: 0;
  color: #fff;
  position: absolute;
  top: 35%;
  right: -180px;
  transform: translateX(-50%);
}
#rs-testimonial2.skew-testimonial2 .slick-next:before {
  color: rgb(20, 6, 43) !important;
}
.bs-sidebar .bs-latest-post .post-item:last-child {
  margin-bottom: 7px;
}
/* -----------------------
    14. Blog Post
--------------------------*/
.rs-blog {
  padding-top: 30px;
}
.rs-blog .blog-item {
  margin-bottom: 30px;
}
.rs-blog .blog-item .blog-img {
  position: relative;
  overflow: hidden;
}
.rs-blog .blog-item .blog-img img {
  width: 100%;
}
.rs-blog .blog-item .blog-img .blog-img-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 30%;
  left: 0;
  text-align: center;
  z-index: 10;
  padding: 20px;
  opacity: 0;
  -webkit-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
}
.rs-blog .blog-item .blog-img .blog-img-content:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
  -webkit-transition: .25s opacity ease;
  transition: .25s opacity ease;
}
.rs-blog .blog-item .blog-img .blog-img-content .blog-link {
  display: inline-block;
  font-size: 27px;
  color: rgb(20, 6, 43);
  transition: all .3s ease 0s;
}
.rs-blog .blog-item .blog-img .blog-img-content .blog-link:hover {
  transform: scale(1.2);
  transition: .25s;
  opacity: 1;
}
.rs-blog .blog-item .blog-img .blog-img-content .blog-title {
  font-size: 18px;
  line-height: normal;
  margin-top: 15px;
  color: #fff;
  margin-bottom: 0;
}
.rs-blog .blog-item .blog-img .blog-img-content .blog-title a {
  color: #fff;
}
.rs-blog .blog-item .blog-img .blog-img-content .blog-title a:hover {
  color: rgba(255, 255, 255, 0.6);
}
.rs-blog .blog-item .blog-meta {
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: all 0.3s ease 0s;
  padding: 9px 30px 8px 25px;
  background: rgb(20, 6, 43);
  color: #fff;
}
.rs-blog .blog-item .blog-meta .blog-date {
  display: inline-block;
  font-weight: 600;
}
.rs-blog .blog-item .blog-meta .blog-lc {
  float: right;
}
.rs-blog .blog-item .blog-meta .blog-lc > div {
  display: inline-block;
}
.rs-blog .blog-item .blog-meta .blog-lc > div:first-child {
  margin-right: 10px;
}
.rs-blog .blog-item .blog-desc {
  margin: 10px 0 20px;
  clear: both;
}
.rs-blog .blog-item .blog-desc a {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  display: block;
  padding: 12px 0 5px;
}
.rs-blog .blog-item .blog-desc a:hover {
  color: rgb(20, 6, 43);
}
.rs-blog .blog-item:hover .blog-img-content {
  top: 0;
  opacity: 1;
}
.rs-blog .pagination-style {
  margin-top: 10px;
}
/*-------#Map---------*/
.g-map {
  width: 100%;
  height: 700px;
  position: relative;
}
/* -----------------------
    15. Contact Us
--------------------------*/
.rs-contact .contact-bg {
  background: white;
  overflow: hidden;
  margin-top: -300px;
  position: relative;
}
.rs-contact .contact-bg .margin {
  padding: 0;
  margin: 0;
}
.rs-contact .contact-bg .contact-address {
  padding: 70px 55px;
  background: rgb(20, 6, 43);
  color: #fff;
}
.rs-contact .contact-bg .contact-address p.contact-title {
  color: #fff;
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: 600;
}
.rs-contact .contact-bg .contact-address p {
  line-height: 24px;
  margin: 0 0 26px;
}
.rs-contact .contact-bg .contact-address .address-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.rs-contact .contact-bg .contact-address .address-item + .address-item {
  margin-top: 25px;
}
.rs-contact .contact-bg .contact-address .address-item .address-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #fff;
  text-align: center;
  font-size: 24px;
  margin-right: 20px;
  transition: all 0.3s ease 0s;
}
.rs-contact .contact-bg .contact-address .address-item:hover .address-icon {
  background-color: rgb(20, 6, 43);
  color: #fff;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom {
  margin-top: 55px;
  margin-bottom: 55px;
  text-align: center;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li {
  display: inline;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li a {
  text-align: center;
  text-decoration: none;
  color: #fff;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li a i {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  line-height: 40px;
  transition: .20s;
  box-shadow: inset 2px 2px 2px;
}
.rs-contact .contact-bg .contact-address .social-icon-bottom ul li a i:hover {
  background: #fff;
  color: rgb(20, 6, 43);
}
.rs-contact .contact-bg #form-messages {
  padding: 15px;
  background: #fff;
  margin-bottom: 30px;
  border-left: 3px solid;
  box-shadow: 0 2px 2px rgba(6, 6, 6, 0.04);
  transition: all 0.3s ease 0s;
}
.rs-contact .contact-bg #form-messages.success {
  border-color: #4caf50;
  color: #4caf50;
}
.rs-contact .contact-bg #form-messages.error {
  border-color: #ff3c3c;
  color: #ff3c3c;
}
.rs-contact .contact-bg #form-messages:empty {
  display: none;
}
.rs-contact .contact-form {
  padding: 70px 55px;
  background: #fff;
}
.rs-contact .contact-form .contact-form-title p.contact-title {
  color: #101010;
  margin: 0 0 28px;
  font-size: 24px;
  font-weight: 600;
}
.rs-contact .contact-form .form-field {
  margin-bottom: 30px;
  position: relative;
}
.rs-contact .contact-form .form-field i {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}
.rs-contact .contact-form .form-field input {
  width: 100%;
  height: 50px;
  padding: 5px 40px;
  border: 1px solid rgb(20, 6, 43);
}
.rs-contact .contact-form .form-field input:focus {
  border: 1px solid #0d421e;
  outline: none;
}
.rs-contact .contact-form .form-field textarea {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid rgb(20, 6, 43);
}
.rs-contact .contact-form .form-field textarea:focus {
  border: 1px solid #0d421e;
  outline: none;
}
.rs-contact .contact-form .form-button button {
  border: 2px solid transparent;
  outline: none;
  font-weight: 600;
  color: #fff;
}
.rs-contact .contact-form .form-button button:hover {
  border: 2px solid rgb(20, 6, 43);
  background: transparent;
  color: #0d421e;
  box-shadow: unset;
}
.video-section-area {
  position: relative;
}
.video-section-area:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(20, 6, 43);
  content: "";
  opacity: 0.2;
  border-radius: 10px;
}
.video-section-area img{
  border-radius: 10px;
}
.video-section-area img.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  ms-transform: translate(-50%, -50%);
  z-index: 11;
}
.rs-error-page {
  /* background-image: url('../images/slider/slide01.jpg'); */
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}
.rs-error-page .error-part {
  display: table;
  text-align: center;
  width: 100%;
  height: 100%;
}
.rs-error-page .error-part:after {
  background: rgba(0, 0, 0, 0.7);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.rs-error-page .error-part .error-text {
  position: relative;
  z-index: 1;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
.rs-error-page .error-part .error-text .error-logo {
  text-align: center;
  margin-bottom: 30px;
}
.rs-error-page .error-part .error-text .error-code {
  font-weight: bold;
  font-size: 120px;
  line-height: 1;
  margin: 0 0 10px 0;
  padding: 0;
  color: #E3D346;
}
.rs-error-page .error-part .error-text .error-message {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 30px;
  color: #fff;
}
.rs-error-page .error-part .error-text a {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}
.rs-error-page .error-part .error-text a:hover {
  color: #fff;
}
/* -----------------------
    16. Footer Css
--------------------------*/
/*========================
	12. FOOTER
=========================*/
footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a {
    color: #b0b0b0;
}
footer p,
footer strong,
footer b,
footer {
    color: #b0b0b0;
}
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a {
    color: #b0b0b0;
}

footer .btn-link,
footer a,
footer p a {
    color: #b0b0b0;
}
footer a:active,
footer a:focus,
footer a:hover {
    color: #b0b0b0;
}
/* widget color */
footer .widget_categories ul li a,
footer .widget_archive ul li a,
footer .widget_meta ul li a,
footer .widget_pages ul li a,
footer .widget_recent_comments ul li a,
footer .widget_nav_menu li a,
footer .widget_recent_entries ul li a,
footer .widget_services ul li a {
    color: #b0b0b0;
}
footer.text-white .widget_categories ul li a, 
footer.text-white .widget_archive ul li a, 
footer.text-white .widget_meta ul li a, 
footer.text-white .widget_pages ul li a, 
footer.text-white .widget_recent_comments ul li a, 
footer.text-white .widget_nav_menu li a, 
footer.text-white .widget_recent_entries ul li a, 
footer.text-white .widget_services ul li a, 
footer.text-white a, 
footer.text-white .footer-bottom, 
footer.text-white p, 
footer.text-white strong, 
footer.text-white b, 
footer.text-white .widget .post-title, 
footer.text-white .widget-about .ttr-title, 
footer.text-white {
    color: #fff;
}
footer p {
    margin-bottom: 10px;
}
footer p,
footer li {
    font-size: 15px;
    line-height: 22px;
}
footer#footer {
    background-position: center;
    background-size: cover;
}
footer p {
    line-height: 24px;
    margin-bottom: 10px;
}
footer .widget ul {
    list-style: none;
    margin-top: 5px;
}
/*widget li in footer*/
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
    border-bottom: 1px dashed rgba(102, 102, 102, 0.3);
}
footer .widget_services ul li {
	transition:all 1s;
	-ms-transition:all 1s;
	-o-transition:all 1s;
	-moz-transition:all 1s;
	-webkit-transition:all 1s;
	padding: 10px 0px 10px 15px;
}
footer .widget_services ul li:hover {
	transform: translateX(10px);
	-moz-transform: translateX(10px);
	-webkit-transform: translateX(10px);
	-o-transform: translateX(10px);
	-ms-transform: translateX(10px);
}
.footer-top {
    background: #161518;
    background-size: cover;
    background-position: center;
    padding: 0 0 20px;
	font-family:rubik;
}
.footer-bottom {
    background-color:#303030;
    padding: 25px 0;
    color: #b0b0b0;
	border-top:1px solid rgba(255,255,255,0.05);
	font-family:rubik;
}
.footer-bottom ul {
    margin: 0;
}
.footer-title{
	margin-bottom: 25px;
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
    padding-bottom: 15px;
    font-size: 16px;
    position: relative;
}
.footer-title:after{
	width: 50px;
    background: #fff;
    opacity: 0.2;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
}
.footer_widget ul li a{
	padding:5px 0;
	display:block;
	font-weight: 400;
}
/* Subscribe Form */
.subscribe-form input{
	background-color:rgba(0,0,0,0.2);
	border:0;
	height:45px;
	padding:10px 20px;
	margin-right:5px;
	color:#fff;
	font-size:15px;
}
.subscribe-form .btn{
	border-radius: 0;
}
.subscribe-form input.radius-no{
	border-radius:0 !important;
}
.subscribe-form .btn{
	height:45px;
}
.subscribe-form .input-group-btn{
	padding-left: 10px;
}
/* scroll top btn css */
button.back-to-top {
    border-radius: 4px;
    border-style: solid;
    border-width: 0;
    bottom: 15px;
    box-shadow: 2px 2px 12px -5px #000000;
    color: #fff;
    cursor: pointer;
    display: none;
    height: 40px;
    line-height: 26px;
    margin: 0;
    position: fixed;
    right: 15px;
    text-align: center;
    width: 40px;
    z-index: 999;
}
/* Footer Extra */
.pt-exebar{
	border-bottom:1px solid rgba(255,255,255,0.1);
	margin-bottom:50px;
}
.pt-social-link{
	border-left:1px solid rgba(255,255,255,0.1);
	padding:0 20px;
}
.pt-social-link ul{
	margin:0;
	list-style:none;
}
.pt-social-link ul li{
	display:inline-block;
}
.pt-btn-join{
	border-left:1px solid rgba(255,255,255,0.1);
	padding:0 0 0 20px;
}
.pt-logo{
	padding:20px 0;
}

.pt-social-link,
.pt-btn-join,
.pt-logo{
	display: flex;
    align-items: center;
}

/*----------------------------------
   18. video Section Index
-----------------------------------*/
.video-sec {
  /* background: url("images/video/2.jpg"); */
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}
.video-sec .sec-title {
  margin-bottom: 38px;
}
.video-sec .pr-md-50 {
  padding-right: 30px;
}
.video-sec .overly {
  padding-top: 100px;
  padding-bottom: 83px;
}
.video-sec .overly:before {
  position: absolute;
  content: '';
  background: rgba(44, 62, 80, 0.9);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.video-sec .overly .sec-title h2,
.video-sec .overly .sec-title p {
  color: #fff;
}
.video-sec .panel-group .panel {
  margin-bottom: 19px;
}
/* ------------------------------------
    19. Counter Up
  ---------------------------------------*/
.counter-top-area {
  /* background: url(../images/slider/slide.jpg) no-repeat scroll 0 0; */
  background-attachment: fixed;
  transition: all 0.3s ease 0s;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.counter-top-area .form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.counter-top-area .overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(20, 6, 43);
  width: 100%;
  height: 100%;
  opacity: 0.9;
}
.counter-top-area .sec-title {
  position: relative;
}
.counter-top-area .sec-title p {
  line-height: 26px;
}
.counter-top-area .sec-title-index-3 h2 {
  color: #fff;
  margin: 0 0 20px;
}
.counter-top-area .sec-title-index-3 p {
  color: #fff;
  line-height: 23px;
  margin: 0 0 12px;
}
.counter-top-area .counter-slider {
  position: relative;
  margin-top: 0;
}
.counter-top-area .counter-slider:after {
  position: absolute;
  content: '';
  height: 50px;
  width: 100%;
  bottom: 0;
  background: #0000003b;
}
.counter-top-area .counter-slider .carousel-indicators {
  bottom: 9px;
}
.counter-top-area .rs-counter-list {
  text-align: center;
  padding: 25px 20px;
  border: 3px solid rgba(255, 255, 255, 0.5);
  transition: .20s;
}
.counter-top-area .rs-counter-list .parsent:after {
  content: '%';
}
.counter-top-area .rs-counter-list:hover {
  border: 3px solid #fff;
}
.counter-top-area .rs-counter-list h3 {
  font-size: 30px;
  font-weight: 700;
  padding: 0;
  color: #ffffff;
  margin-top: 20px !important;
  margin-bottom: 12px;
}
.counter-top-area .rs-counter-list h4 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 20px;
}
.counter-top-area .rs-counter-list i {
  color: #fff;
  display: inline-block;
  font-size: 50px;
}
.counter-top-area .sidebar-count .rs-counter-list {
  border: none;
  padding-top: 0;
  padding-bottom: 35px;
}
.counter-top-area .sidebar-count .rs-counter-list h3 {
  margin-top: 9px !important;
}
.counter-top-area.defult-style {
  background: none;
  /* margin-top: 70px; */
}
.counter-top-area.defult-style .rs-counter-list {
  border-color: rgb(20, 6, 43);
  border-radius: 8px!important;
  border-radius: 0;
  border-width: 1px;
  padding: 35px 20px;
}
.counter-top-area.defult-style .rs-counter-list h3 {
  margin: 20px 0 10px !important;
}
.counter-top-area.defult-style .rs-counter-list h6 {
  margin: 20px 0 10px !important;
}
.counter-top-area.defult-style .rs-counter-list h4 {
  margin: 0;
  position: relative;
  padding: 0;
}
.counter-top-area.defult-style .rs-counter-list i,
.counter-top-area.defult-style .rs-counter-list h3,
.counter-top-area.defult-style .rs-counter-list h4 {
  color: rgb(20, 6, 43);
}
/* ------------------------------------
    20. Breadcrumbs Css
  ---------------------------------------*/
.rs-breadcrumbs.cover {
  /* background: url(../images/header/1.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-smc {
  /* background: url(../images/header/smc.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-aiph {
  /* background: url(../images/header/aiph.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-it {
  /* background: url(../images/header/it.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-career {
  /* background: url(../images/header/career.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-media {
  /* background: url(../images/header/media.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-con {
  /* background: url(../images/header/conn.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-iohbm {
  /* background: url(../images/header/iohbm.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-ips {
  /* background: url(../images/header/ips.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-tenders {
  /* background: url(../images/header/tenders.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-siohs {
  /* background: url(../images/header/siohs.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-qec {
  /* background: url(../images/header/qec.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-cofm {
  /* background: url(../images/header/cofm.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.cover-ime {
  /* background: url(../images/header/ime.jpg) center top no-repeat; */
  background-size: cover;
}
.rs-breadcrumbs.sec-spacer {
  padding: 20px 0;
}
.rs-breadcrumbs .page-title {
  font-size: 26px;
  margin: 4px 0 0;
  letter-spacing: 0.5px;
  color: #ffffff;
  float: left;
  line-height: 50px;
}
.rs-breadcrumbs .breadcrumbs-inner {
  overflow: hidden;
}
.rs-breadcrumbs .breadcrumbs-inner img{
  height: 80px;
  width: auto;
  padding-right: 30px;
  display: inline-block;
  float: left;
}

.rs-breadcrumbs ul {
  display: inline-block;
  background: rgba(0, 0, 0, 0.2);
  padding: 5px 20px;
  float: right;
  margin-top: 8px;
}
.rs-breadcrumbs ul li {
  color: #E6E45E;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}
.rs-breadcrumbs ul li a {
  color: #ffffff;
  position: relative;
  padding-right: 24px;
}
.rs-breadcrumbs ul li a:after {
  background-color: #cccccc;
  content: "";
  height: 15px;
  width: 2px;
  position: absolute;
  right: 8px;
  top: 5px;
  transform: rotate(33deg);
}
.rs-breadcrumbs ul li a.active {
  color: #ffffff;
}
/* ------------------------------------
    21. Portfolio Details
  ---------------------------------------*/
.rs-porfolio-details .ps-informations {
  margin-top: 20px;
}
.rs-porfolio-details .ps-informations .info-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.rs-porfolio-details .ps-informations span {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.rs-porfolio-details div ul li{
  text-align: justify;
}
.rs-porfolio-details .ps-informations ul li + li {
  padding-top: 7px;
  margin-top: 7px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.rs-porfolio-details .ps-informations ul li span {
  width: 120px;
  display: inline-block;
}
.rs-porfolio-details .ps-share {
  margin-top: 20px;
}
.rs-porfolio-details .ps-share .share-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.rs-porfolio-details .ps-share ul li {
  display: inline-block;
  margin: 0 10px 10px 0;
}
.rs-porfolio-details .ps-share ul li a {
  font-size: 14px;
  display: block;
  width: 30px;
  height: 28px;
  line-height: 28px;
  border: 1px solid rgb(20, 6, 43);
  text-align: center;
  transition: all 0.3s ease 0s;
}
.rs-porfolio-details .ps-share ul li a:hover {
  background-color: rgb(20, 6, 43);
  color: #fff;
}
.rs-porfolio-details .project-desc {
  margin-top: 35px;
  line-height: 30px;
}
.rs-porfolio-details .project-desc .ps-title {
  margin-bottom: 20px;
}
.project-desc .ps-title {
  margin-top: 20px;
}
.project-desc .ps-title2 {
  margin-top: 20px;
  margin-bottom: 5px;
}
.project-desc ul {
  list-style-type: square;
  padding-left: 40px;
}
.project-desc p {
  text-align: justify;
}
.project-desc ul .desctext2 {
  text-align: justify;
  padding-bottom: 20px;
}
.rs-porfolio-details .ps-related-project {
  margin-top: 50px;
}
.rs-porfolio-details .ps-related-project .ps-related-title {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
}
.rs-porfolio-details .ps-related-project .portfolio-item {
  margin-bottom: 20px;
}
.rs-porfolio-details .ps-navigation {
  margin-top: 30px;
}
.rs-porfolio-details .ps-navigation ul {
  display: -webkit-flex;
  /* Safari */
  -webkit-justify-content: space-between;
  /* Safari 6.1+ */
  display: flex;
  justify-content: space-between;
}
.rs-porfolio-details .ps-navigation ul a {
  padding: 10px 20px;
  text-transform: uppercase;
}
/* ------------------------------------
    22. Blog Details
  ---------------------------------------*/
.rs-blog-details .bs-title {
  margin-top: 30px;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 5px;
}
.rs-blog-details .bs-img img {
  width: 100%;
}
.rs-blog-details .bs-meta {
  font-size: 13px;
}
.rs-blog-details .bs-meta li {
  display: inline-block;
  line-height: 10px;
}
.rs-blog-details .bs-meta li + li {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #ddd;
}
.rs-blog-details .bs-meta li a {
  color: #757575;
}
.rs-blog-details .bs-meta li a:hover {
  color: #212121;
}
.rs-blog-details .bs-desc {
  margin-top: 20px;
}
.rs-blog-details .blockquote {
  padding-left: 30px;
  border-left: 5px solid #444;
  display: block;
  font-size: 18px;
  line-height: 1.5;
  margin: 25px 0;
}
.rs-blog-details .related-post {
  margin-top: 40px;
  padding-top: 20px;
}
.rs-blog-details .related-post .related-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}
.rs-blog-details .author-block {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 38px;
  border: 1px solid rgb(20, 6, 43);
  padding: 35px;
}
.rs-blog-details .author-block .author-img {
  margin-right: 35px;
}
.rs-blog-details .author-block .author-title {
  margin-bottom: 15px;
  font-size: 20px;
}
.rs-blog-details .author-block .author-title a {
  color: #212121;
}
.rs-blog-details .author-block p {
  margin-bottom: 15px;
}
.rs-blog-details .comment-form {
  margin-top: 50px;
  margin-bottom: 30px;
}
.rs-blog-details .comment-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}
.rs-blog-details .comment-form .comment-title {
  margin-bottom: 30px;
}
.rs-blog-details .comment-block {
  margin-top: 67px;
}
.rs-blog-details .comment-block .comment {
  padding: 25px 25px 0px 25px;
  background: #fff;
  -webkit-box-shadow: 0 0px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 0px;
}
.rs-blog-details .comment-block .comment .comment-header {
  margin-bottom: 10px;
}
.rs-blog-details .comment-block .comment .comment-header:before,
.rs-blog-details .comment-block .comment .comment-header:after {
  content: "";
  display: table;
}
.rs-blog-details .comment-block .comment .comment-header:after {
  clear: both;
}
.rs-blog-details .comment-block .comment img {
  float: left;
  margin-right: 15px;
}
.rs-blog-details .comment-block .comment h5 {
  margin: 5px 0 0 0;
  font-size: 16px;
  line-height: 22px;
}
.rs-blog-details .comment-block .comment .comment-body p.desc {
  margin-bottom: 10px;
}
.rs-blog-details .comment-block .comment .comment-reply {
  margin-bottom: 0;
}
.rs-blog-details .rs-contact {
  background: transparent;
}
.rs-blog-details .rs-contact .contact-form {
  padding: 0;
}
/* ------------------------------------
    23. Blog Sidebar
---------------------------------------*/
.bs-sidebar {
  padding-right: 30px;
  border-right: 1px solid #D7D7D7;
}
.bs-sidebar .bs-widget-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.bs-sidebar .bs-search {
  overflow: hidden;
  position: relative;
}
.bs-sidebar .bs-search input {
  border: 1px solid #0d421e;
  color: #000000;
  padding: 10px;
  width: 100%;
}
.bs-sidebar .bs-search button {
  background: transparent;
  border: medium none;
  color: rgb(20, 6, 43);
  padding: 11px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  font-size: 20px;
  outline: none;
}
.bs-sidebar .bs-caterories ul li{
  padding: 10px 0px 10px 10px;
  margin: 3px 0px;
  border-top: 1px solid #D5D5D5;
}
.bs-sidebar .bs-caterories ul:first-child li{
  padding: 10px 0px 10px 0px;
  margin: 0px 0px;
  border-top: 1px solid #D5D5D5;
}
.bs-sidebar .bs-caterories ul:first-child li:first-child{
  border: none;
}
.bs-sidebar .bs-caterories ul .active,
.bs-sidebar .bs-caterories ul .active a {
  color: #BA3D20;
  background: #F3FCF2;
}
.bs-sidebar .bs-caterories ul li a {
  color: rgb(20, 6, 43);
  font-weight: 600;
  transition: all 0.3s ease 0s;
}
.bs-sidebar .bs-caterories ul li a:hover {
  color: #BA3D20;
}
.bs-sidebar .bs-caterories ul li span {
  float: right;
}
.bs-sidebar .bs-latest-post .post-item {
  margin-bottom: 20px;
}
.bs-sidebar .bs-latest-post .post-desc {
  padding: 15px 0;
}
.bs-sidebar .bs-latest-post .post-desc .title {
  display: block;
  font-size: 17px;
  color: #444444;
  transition: all 0.3s ease 0s;
}
.bs-sidebar .bs-latest-post .post-desc .title:hover {
  color: rgb(20, 6, 43);
}
.bs-sidebar .bs-latest-post .post-desc span {
  display: block;
  font-size: 13px;
  color: #888;
}
.bs-sidebar .bs-tags a {
  display: inline-block;
  margin: 0 3px 7px 0;
  line-height: 20px;
  padding: 6px 12px;
  font-size: 13px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  color: rgb(20, 6, 43);
  border: 1px solid rgb(20, 6, 43);
  border-radius: 2px;
}
.bs-sidebar .bs-tags a:hover {
  background-color: rgb(20, 6, 43);
  color: #fff;
}
/* ------------------------------------
    24. Blog Pagination
---------------------------------------*/
.pagination-area {
  padding-top: 30px;
}
.pagination-area ul {
  text-align: center;
}
.pagination-area ul li {
  display: inline-block;
  margin: 0 3px 0 0;
}
.pagination-area ul li.active a {
  background: rgb(20, 6, 43);
  color: #ffffff;
}
.pagination-area ul li a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: transparent;
  border: 1px solid rgb(20, 6, 43);
  color: #000000;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  text-align: center;
}
.pagination-area ul li a:hover {
  background: rgb(20, 6, 43);
  color: #ffffff;
}
/* ------------------------------------
    25. Home Banner
---------------------------------------*/
.rs-banner-area {
  /* background: url("images/banner/1.jpg") no-repeat top center; */
  position: relative;
  background-size: cover;
  transition: all 0.3s ease 0s;
  overflow: hidden;
  padding: 180px 0;
}
.rs-banner-area ul {
  margin-top: 45px;
}
.rs-banner-area ul li {
  margin-right: 20px;
  float: left;
  margin-bottom: 14px;
}
.rs-banner-area ul li .sl-btn {
  border: 2px solid #fff !important;
  padding: 15px 35px;
  transition: all 0.3s ease 0s;
  border-radius: 40px;
  letter-spacing: 2px;
  background: transparent !important;
  line-height: 56px;
  color: #ffffff;
}
.rs-banner-area ul li .sl-btn:hover {
  background: #fff !important;
  color: #000;
}
.rs-banner-area .sl-subtitle {
  font-size: 40px;
  margin-bottom: 8px;
  font-weight: 700;
}
.rs-banner-area .sl-title {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
}
.rs-banner-area .sl-desc {
  margin-bottom: 35px;
  line-height: 22px;
  font-size: 22px;
}
.rs-banner-area .display-table {
  margin-top: 0;
}
.rs-banner-area .display-table-cell {
  position: relative;
  z-index: 10;
}
.rs-banner-area .overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
#particles-js {
  /* background: url(images/particle-banner/1.jpg) no-repeat top center / cover !important; */
  height: 800px;
  background-size: cover;
  position: relative;
}
#particles-js:after {
  content: "";
  background: rgba(44, 62, 80, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#particles-js .particles-js-canvas-el {
  position: relative;
  z-index: 99;
}
.skew-sectiom {
  background: none;
  height: auto;
  position: relative;
}
.skew-sectiom:after {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.skew-sectiom .rs-banner-content-wrap2 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
}
.skew-sectiom .rs-banner-content-wrap2 .container {
  text-align: center;
}
.skew-sectiom .rs-banner-content-wrap2 .container .sl-subtitle {
  font-size: 50px;
}
.skew-sectiom .rs-banner-content-wrap2 .container .sl-title {
  font-size: 65px;
}
.skew-sectiom .rs-banner-content-wrap2 .container .slider-botton {
  text-align: center;
  margin-top: 30px;
}
.skew-sectiom .rs-banner-content-wrap2 .container .slider-botton li {
  display: inline-block;
}
.skew-sectiom .rs-banner-content-wrap2 .container .slider-botton li .readon {
  padding: 14px 40px;
  font-size: 20px;
}
.skew-sectiom .rs-banner-content-wrap2 .container .slider-botton li .mr-25 {
  border: 2px solid;
}
.skew-sectiom .rs-banner-content-wrap2 .container .slider-botton li .white-color {
  padding: 16px 45px;
}
#rs-particles-banner-area {
  padding: 0;
  background: none;
  position: absolute;
  top: 54%;
  left: 50%;
  display: block;
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Safari */
  transform: translate(-50%, -50%);
  z-index: 111;
}
#rs-particles-banner-area .rs-banner-content-wrap {
  text-align: center;
}
#rs-particles-banner-area .rs-banner-content-wrap .container .sl-subtitle {
  padding: 0;
  margin: 0;
  font-size: 40px;
}
#rs-particles-banner-area .rs-banner-content-wrap .container .sl-title {
  margin: 15px 0;
  padding: 0;
  font-size: 60px;
}
#rs-particles-banner-area .rs-banner-content-wrap .container .sl-desc {
  padding: 0;
  margin: 0 0 35px;
}
#rs-particles-banner-area .rs-banner-content-wrap ul li {
  float: none;
  display: inline-block;
}
#rs-particles-banner-area .rs-banner-content-wrap ul li .readon {
  padding: 14px 40px;
  font-size: 20px;
}
#rs-particles-banner-area .rs-banner-content-wrap ul li .mr-25 {
  border: 2px solid;
}
#rs-particles-banner-area .rs-banner-content-wrap ul li .white-color {
  padding: 16px 45px;
}
#rs-particles-banner-area .overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
#rs-particles-banner-area .white-color {
  padding: 12px 35px;
}
.vieo-sec {
  position: relative;
}
.vieo-sec:after {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.vieo-sec .videos-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  border: 2px solid rgb(20, 6, 43);
  padding: 5px;
  border-radius: 50%;
}
.vieo-sec .videos-icon a {
  font-size: 50px;
  line-height: 110px;
  border: 2px solid #fff;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  text-align: center;
  color: rgb(20, 6, 43);
  display: block;
  background: #fff;
}
.vieo-sec .videos-icon a:hover {
  background: transparent;
  color: rgb(20, 6, 43);
  border: 2px solid rgb(20, 6, 43);
}
.vieo-sec .videos-icon a i {
  position: relative;
  left: 5px;
  top: -3px;
}
.particles-about .signature {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: #111;
}
.particles-about .signature span {
  font-size: 22px;
  font-style: normal;
  display: block;
}
.particles-about .signature p {
  margin: 0;
}
.particles-about .signature img {
  width: 150px;
}
.particles-about .about-exp {
  margin-top: 25px !important;
}
.particles-about .sec-title {
  margin-bottom: 20px;
}
.particles-faq {
  /* background: url(images/faq/bg.jpg) no-repeat center top; */
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}
.particles-faq:after {
  content: "";
  background: rgba(33, 170, 80, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.particles-faq .container {
  position: relative;
  z-index: 9;
}
.particles-faq .container .sec-title h3,
.particles-faq .container .sec-title h4 {
  color: #fff;
}
.particles-faq .container p {
  margin: 0 0 16px;
}
.particles-counter {
  position: relative;
}
.particles-counter:after {
  content: "";
  background: rgba(33, 170, 80, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.particles-counter .container {
  position: relative;
  z-index: 9;
}
.particles-counter .container h3 {
  color: #fff;
}
.particles-counter .container i {
  color: #fff;
}
.particles-newsletter {
  position: relative;
}
.particles-newsletter:after {
  content: "";
  background: rgba(33, 170, 80, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.particles-newsletter .container {
  position: relative;
  z-index: 9;
}
.particles-newsletter .container h3 {
  color: #fff;
}
.particles-newsletter .container i {
  color: #fff;
}
.particles-newsletter .container .news-form .form-input {
  border-color: #fff;
}
.particles-newsletter .container .news-form .form-button {
  background: #fff;
  color: #000;
}
.particles-newsletter .container .news-form .form-button:hover {
  color: rgb(20, 6, 43);
}
.particles-portfolio .portfolio-filter button {
  padding: 8px 18px;
  border-radius: 24px 24px 24px 24px;
}
.particles-portfolio .portfolio-filter button:before,
.particles-portfolio .portfolio-filter button:after {
  display: none;
  opacity: 0;
}
.particles-portfolio .portfolio-filter button:hover {
  color: #fff;
  background: rgb(20, 6, 43);
  padding: 8px 18px;
  border-radius: 24px 24px 24px 24px;
}
.particles-portfolio .portfolio-filter button.active {
  color: #fff;
  background: rgb(20, 6, 43);
  padding: 8px 18px;
  border-radius: 24px 24px 24px 24px;
}
.footer-form {
  background: #fff;
  border-radius: 30px 30px 30px 30px;
  position: relative;
  outline: unset;
}
.footer-form .form-input {
  border: none;
  border-radius: 30px 30px 30px 30px;
  padding: 10px 0 10px 16px;
  background: #fff;
  color: #757575;
  outline: none;
  width: 100%;
}
.footer-form .form-input ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #757575;
}
.footer-form .form-input ::-moz-placeholder {
  /* Firefox 19+ */
  color: #757575;
}
.footer-form .form-input :-ms-input-placeholder {
  /* IE 10+ */
  color: #757575;
}
.footer-form .form-input :-moz-placeholder {
  /* Firefox 18- */
  color: #757575;
}
.footer-form .form-button {
  position: absolute;
  border-radius: 0 30px 30px 0;
  top: 0;
  right: -2px;
  background: #0d421e;
  color: #fff;
  padding: 10px 16px;
  border: none;
  transition: 0.20s;
}
.footer-form .form-button:hover {
  color: #f9f9f9;
}
.skew-sectiom .particles-js-canvas-el {
  opacity: 0;
}
.skew-sectiom-services {
  position: relative;
  background: #fff;
}
.skew-sectiom-services:before {
  transform: skewY(-4deg) scale(1);
  content: "";
  background: rgb(20, 6, 43);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.skew-sectiom-services .sec-title h3,
.skew-sectiom-services .sec-title h4 {
  color: #fff;
  position: relative;
}
.skew-sectiom-services .services-wrap .services-item i {
  color: #fff;
}
.skew-sectiom-services .services-wrap .services-item .services-desc {
  color: #fff;
}
.skew-sectiom-services .services-wrap .services-item .services-desc .services-title {
  color: #fff;
}
.skew-sectiom-services .services-wrap .services-item .services-desc:after {
  background: #fff !important;
}
.skew-sectiom-services .services-wrap .services-item:after {
  opacity: 0;
  display: none;
}
.skew-sectiom-services .services-wrap .services-item:before {
  opacity: 0;
  display: none;
}
.skew-sectiom-skills {
  background: #fff;
}
.skew-sectiom-faq {
  position: relative;
  background: #fff;
}
.skew-sectiom-faq:before {
  transform: skewY(-4deg) scale(1);
  content: "";
  background: rgb(20, 6, 43);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.skew-sectiom-faq .sec-title h3,
.skew-sectiom-faq .sec-title h4 {
  color: #fff;
  position: relative;
}
#rs-team .team-carousel,
#rs-customer .team-carousel,
#rs-team .customer-carousel,
#rs-customer .customer-carousel {
  position: relative;
}
#rs-team .team-carousel .owl-nav,
#rs-customer .team-carousel .owl-nav,
#rs-team .customer-carousel .owl-nav,
#rs-customer .customer-carousel .owl-nav {
  top: 50% !important;
  width: 100%;
  transform: translateY(-50%);
  opacity: 0;
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
}
#rs-team .team-carousel .owl-nav .owl-prev,
#rs-customer .team-carousel .owl-nav .owl-prev,
#rs-team .customer-carousel .owl-nav .owl-prev,
#rs-customer .customer-carousel .owl-nav .owl-prev {
  float: left;
  left: -60px !important;
}
#rs-team .team-carousel .owl-nav .owl-next,
#rs-customer .team-carousel .owl-nav .owl-next,
#rs-team .customer-carousel .owl-nav .owl-next,
#rs-customer .customer-carousel .owl-nav .owl-next {
  float: right;
  right: -60px;
}
#rs-team:hover .owl-nav,
#rs-customer:hover .owl-nav {
  opacity: 1;
}
#rs-blog .blog-carousel {
  position: relative;
}
#rs-blog .blog-carousel .owl-nav {
  top: 33% !important;
  width: 100%;
  transform: translateY(-33%);
  opacity: 0;
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
}
#rs-blog .blog-carousel .owl-nav .owl-prev {
  float: left;
  left: -60px !important;
}
#rs-blog .blog-carousel .owl-nav .owl-next {
  float: right;
  right: -60px;
}
#rs-blog:hover .owl-nav {
  opacity: 1;
}
.testi-carousel .slick-arrow {
  display: none !important;
}
.skew-testimonial {
  position: relative;
  background: #fff !important;
}
.skew-testimonial:before {
  transform: skewY(-4deg) scale(1);
  content: "";
  background: rgb(20, 6, 43);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.skew-testimonial .sec-title h3,
.skew-testimonial .sec-title h4 {
  color: #fff;
  position: relative;
}
.skew-testimonial .rs-tst-content .rs-tst-title,
.skew-testimonial .rs-tst-content .rs-tst-designation,
.skew-testimonial .rs-tst-content .rs-tst-details p {
  color: #fff !important;
}
.skew-sectiom-counter {
  position: relative;
  background: #fff;
}
.skew-sectiom-counter:before {
  transform: skewY(-4deg) scale(1);
  content: "";
  background: rgb(20, 6, 43);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.skew-sectiom-counter h3,
.skew-sectiom-counter h4 {
  color: #fff;
  position: relative;
}
.skew-sectiom-counter .rs-counter-list i {
  color: #fff;
}
.skew-sectiom-counter .rs-counter-list h3 {
  color: #fff;
}
/* ------------------------------------
    26. Rs Video Area
---------------------------------------*/
.rs-video-area {
  height: 700px;
  position: relative;
}
.rs-video-area .rs-video-content-wrap .display-table .over-content {
  z-index: 999;
  position: relative;
  overflow: hidden;
  margin-top: 165px;
}
.rs-video-area .rs-video-content-wrap .display-table .over-content .sl-subtitle {
  font-size: 45px;
  line-height: 50px;
  margin-bottom: 8px;
  font-weight: 700;
}
.rs-video-area .rs-video-content-wrap .display-table .over-content .sl-title {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 65px;
  font-weight: 700;
}
.rs-video-area .rs-video-content-wrap .display-table .over-content .sl-desc {
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 47px;
}
.rs-video-area .rs-video-content-wrap .display-table .over-content .slider-botton {
  margin-bottom: 30px;
}
.rs-video-area .rs-video-content-wrap .display-table .over-content .slider-botton ul li {
  display: inline-block;
}
.rs-video-area .rs-video-content-wrap .display-table .over-content .slider-botton ul li a.normal {
  padding: 15px 50px;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  transition: .25s;
}
.rs-video-area .rs-video-content-wrap .display-table .over-content .slider-botton ul li a.normal:hover {
  border: 2px solid #0d421e;
  background: #0d421e;
}
.rs-video-area .rs-video-content-wrap .display-table .over-content .slider-botton ul li a.active {
  padding: 15px 50px;
  border: 2px solid #0d421e;
  background: #0d421e;
  border-radius: 30px;
  color: #fff;
}
.rs-video-area:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.55);
}
/* ------------------------------------
    27. Our History Section
---------------------------------------*/
.our-hostory-section .single-service {
  margin-bottom: 50px;
  border: 1px solid #d7d7d7;
}
@media screen and (max-width: 991px) {
  .our-hostory-section .single-service {
    margin-bottom: 30px;
  }
}
.our-hostory-section .single-service .service-img {
  position: relative;
}
.our-hostory-section .single-service .service-img:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #101010;
  opacity: 0.7;
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -mz-transition: 0.4s;
}
.our-hostory-section .single-service .service-img i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  ms-transform: translate(-50%, -50%);
  z-index: 999;
  font-size: 30px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -ms-transition: 0.3s;
}
.our-hostory-section .single-service .service-details {
  padding: 0 9px;
}
.our-hostory-section .single-service .service-details h3 {
  margin: 18px 0 8px;
}
.our-hostory-section .single-service .service-details h3 a {
  font-weight: 600;
  font-size: 20px;
  color: #101010;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -mz-transition: 0.4s;
}
.our-hostory-section .single-service .service-details a.primary-btn {
  background: rgb(20, 6, 43);
  color: #101010;
  font-size: 15px;
  padding: 5px 24px;
  display: inline-block;
  margin-bottom: 25px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
}
.our-hostory-section .single-service:hover .service-img:after {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
}
.our-hostory-section .single-service:hover .service-details a.primary-btn {
  color: #fff;
  background: #101010;
}
.our-hostory-section .single-service:hover .service-details h3 a {
  color: rgb(20, 6, 43);
}
.our-hostory-section .single-service:hover i {
  opacity: 1;
  visibility: visible;
}
.our-hostory-section .service-list-sidebar ul#service-menu li {
  display: block;
  position: relative;
}
.our-hostory-section .service-list-sidebar ul#service-menu li a {
  color: #757575;
  font-weight: 400;
  padding: 10px 0;
  border-bottom: 1px solid #ebebeb;
  display: block;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -mz-transition: 0.4s;
}
.our-hostory-section .service-list-sidebar ul#service-menu li a:after {
  content: "›";
  font-size: 17px;
  font-family: Georgia, serif;
  position: absolute;
  right: 11px;
  top: 7px;
  color: #757575;
}
.our-hostory-section .service-list-sidebar ul#service-menu li a:hover:after,
.our-hostory-section .service-list-sidebar ul#service-menu li a:hover {
  color: #dc0000;
}
.our-hostory-section .service-list-sidebar ul#service-menu li.active a {
  color: #dc0000;
}
.our-hostory-section .services-left-side {
  margin-top: 40px;
}
.our-hostory-section .services-left-side h4 {
  font-size: 20px;
  margin: 15px 0 8px;
  padding-bottom: 12px;
}
.our-hostory-section .services-left-side h4:after {
  height: 3px;
}
.our-hostory-section .services-left-side ul#brochures-list {
  margin-top: 30px;
}
.our-hostory-section .services-left-side ul#brochures-list li {
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
}
.our-hostory-section .services-left-side ul#brochures-list li a {
  color: #757575;
  text-transform: uppercase;
  display: block;
}
.our-hostory-section .services-left-side ul#brochures-list li a i {
  background: #ccc;
  padding: 14px;
  color: #757575;
  margin-right: 20px;
}
.our-hostory-section .services-left-side ul#brochures-list li a:hover {
  color: rgb(20, 6, 43);
}
.our-hostory-section .services-left-side.our-office {
  margin-top: 48px;
}
.our-hostory-section .services-left-side.our-office ul#office-address {
  margin-top: 30px;
}
.our-hostory-section .services-left-side.our-office ul#office-address li {
  margin-bottom: 20px;
  color: #757575;
  font-size: 15px;
  font-weight: 400;
  overflow: hidden;
  padding-left: 35px;
  position: relative;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
}
.our-hostory-section .services-left-side.our-office ul#office-address li a {
  color: #757575;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
}
.our-hostory-section .services-left-side.our-office ul#office-address li i {
  color: #757575;
  position: absolute;
  left: 0;
  top: 5px;
}
.our-hostory-section .services-left-side.our-office ul#office-address li span {
  overflow: hidden;
}
.our-hostory-section .services-left-side.our-office ul#office-address li a:hover {
  color: rgb(20, 6, 43);
}
.our-hostory-section#services-sliders {
  padding-bottom: 100px;
}
.our-hostory-section#services-sliders .single-service {
  margin-bottom: 1px;
}
.our-hostory-section .include {
  margin-top: 35px;
}
.our-hostory-section .include .panel-body p {
  margin: 12px 0;
}
.our-hostory-section .include .panel-body .panel-title a.collapsed {
  background: #000;
}
.our-hostory-section .services-btm {
  margin-top: 35px;
}
.our-hostory-section .services-btm .title-border {
  margin-left: 15px;
}
.our-hostory-section .services-btm ul li {
  position: relative;
  padding-left: 30px;
}
.our-hostory-section .services-btm ul li:before {
  content: "\f00c";
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 99999;
  color: rgb(20, 6, 43);
  font-size: 18px;
}
/* ---------------------------
  28. Faq Section Start
------------------------------*/
.faq-section {
  padding: 92px 0 90px;
}
.faq-section .sec-title {
  margin-bottom: 30px;
}
.faq-section .sec-title h2 {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 30px;
  text-align: left;
}
.faq-section .keyword-left .keyword-image {
  background: #f2f2f2;
}
.faq-section #accordion .panel {
  border-radius: 0;
  margin-bottom: 12px;
}
.faq-section #accordion .panel .panel-heading {
  background: #ededed;
  border: none;
  border-radius: 0;
  padding: 17px 25px;
  border-radius: 30px;
  position: relative;
}
.faq-section #accordion .panel .panel-heading .panel-title {
  margin: 0;
  line-height: 1;
}
.faq-section #accordion .panel .panel-heading .panel-title a {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #757575;
  position: relative;
  display: block;
  padding-left: 65px;
}
.faq-section #accordion .panel .panel-heading .panel-title a:after {
  color: #fff;
  content: "";
  font-family: FontAwesome;
  font-size: 20px;
  height: 50px;
  left: -27px;
  padding: 16px 26px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 9;
}
.faq-section #accordion .panel .panel-heading .panel-title a.collapsed:after {
  content: "\f067";
}
.faq-section .form-group {
  margin-bottom: 29px;
}
.faq-section .form-group input[type=text],
.faq-section .form-group input[type=email],
.faq-section .form-group textarea#message {
  width: 100%;
  border: 1px solid rgb(20, 6, 43);
  padding: 15px;
  height: auto;
  border-radius: 0;
}
.faq-section .form-group input[type=submit] {
  width: 100%;
  height: 55px;
  font-size: 20px;
  margin-top: 20px;
  display: block;
  border: 1px solid #0d421e;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 30px;
  background: #0d421e;
  color: #fff;
  text-transform: uppercase;
}
.faq-section .form-group input[type=submit]:hover {
  background: rgb(20, 6, 43);
  border: 1px solid rgb(20, 6, 43);
}
/* ---------------------------
   29. CTA Section Start
------------------------------*/
.rs-home-cta {
  padding: 45px 0 47px;
  background: rgb(20, 6, 43);
}
.rs-home-cta .cta-home-text {
  text-align: left;
}
.rs-home-cta .cta-home-text h3 {
  font-size: 36px;
  line-height: 100%;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
.rs-home-cta .cta-home-text p {
  margin-bottom: 0px;
  color: #ffffff;
  font-size: 15px;
  line-height: 120%;
}
.rs-home-cta .rs-home-cta-button {
  text-align: right;
  margin-top: 5px;
}
.rs-home-cta .rs-home-cta-button .cta-button {
  width: 190px;
  height: 56px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 30px;
  display: inline-block;
  right: 0;
  text-align: center;
  line-height: 54px;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
}
.rs-home-cta .rs-home-cta-button .cta-button:hover {
  background-color: #0d421e;
}
/* ---------------------------
  30. Responsibiity Section Start
------------------------------*/
.rs-responsibiity .sec-title {
  margin-bottom: 35px;
}
.rs-responsibiity .sec-title h3 {
  margin-bottom: 12px;
}
.rs-responsibiity .services-middle-img.pr-md-50 {
  padding-right: 15px;
}
.rs-responsibiity .services-item {
  z-index: 10;
  overflow: hidden;
  width: 100%;
}
.rs-responsibiity .services-item i {
  font-size: 45px;
}
.rs-responsibiity .services-item .service-mid-icon {
  width: 80px;
  float: left;
  margin-bottom: 10px;
}
.rs-responsibiity .services-item .services-desc {
  overflow: hidden;
}
.rs-responsibiity .services-item .services-desc .services-title {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
}
.rs-responsibiity .services-item .services-desc .services-title a {
  color: #101010;
}
.rs-responsibiity .services-item .services-desc p {
  font-size: 14px;
}
.rs-responsibiity .services-item:last-child .services-desc p {
  margin-bottom: 0;
}
.rs-responsibiity .services-wrap {
  margin-top: 30px;
}
.rs-responsibiity .services-wrap .services-item {
  padding: 0 0 20px;
}
.rs-responsibiity .services-wrap .services-item .service-mid-icon {
  width: 100%;
}
.rs-responsibiity .services-wrap .services-item .service-mid-icon i {
  font-size: 36px;
  text-align: center;
  color: #fff;
  background-color: rgb(20, 6, 43);
  padding: 12px 12px 0;
}
.rs-responsibiity .services-wrap .services-item .service-mid-icon i:before {
  line-height: 2;
}
.rs-responsibiity .services-wrap .services-item .services-desc {
  width: 100%;
}
.rs-responsibiity .services-wrap .services-item .services-desc p {
  font-size: 14px;
  margin: 0;
}
.rs-responsibiity .services-wrap .services-item:last-child {
  padding-bottom: 0;
}
.include.history-page {
  margin-top: 20px;
}
/* ------------------------------------
    31. Leadership Section
---------------------------------------*/
.leadership-section .grid-style-13 img {
  width: 150px;
  height: 150px;
}
.leadership-section .grid-style-13 .single-team-member {
  /*width: 100%;*/
  width: 550px;
  margin: 0 0 30px;
  overflow: hidden;
}
.leadership-section .grid-style-13 .single-team-member .image-section {
  /*width: 50%;*/
  width: 150px;
  float: left;
  position: relative;
  background-color: #000;
  opacity: 0.8;
}
.leadership-section .grid-style-13 .single-team-member .image-section .overlay-section {
  background-color: #000;
  opacity: 0;
  transition: 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.leadership-section .grid-style-13 .single-team-member .image-section .overlay-section .detail-link {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
}
.leadership-section .grid-style-13 .single-team-member .image-section .overlay-section .detail-link i {
  color: #fff;
  font-size: 19px;
  background: rgb(20, 6, 43);
  border-radius: 50%;
  height: 46px;
  width: 46px;
  line-height: 47px;
  display: inline-block;
  text-align: center;
  transition: .3s all ease;
}
.leadership-section .grid-style-13 .single-team-member .image-section .overlay-section .detail-link i:hover {
  background: rgb(20, 6, 43);
}
.leadership-section .grid-style-13 .single-team-member .image-section img {
  width: 100%;
  transform: scale(0.9);
  transition: 0.5s;
}
.leadership-section .grid-style-13 .single-team-member .image-section:hover .overlay-section {
  opacity: 0.8;
}
.leadership-section .grid-style-13 .single-team-member .box-section {
  width: 50%;
  float: left;
  position: relative;
  background-color: #f2f2f2;
  transition: 0.5s;
}
.leadership-section .grid-style-13 .single-team-member .box-section:before {
  position: absolute;
  content: "";
  border-right: 15px solid rgb(20, 6, 43);
  border-bottom: 15px solid transparent;
  border-top: 15px solid transparent;
  bottom: 15px;
  left: -15px;
  opacity: 0;
  transition: 0.5s;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text {
  /*text-align: center;*/
  /*padding: 10% 0;*/
  padding: 20px;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .box-title {
  /*margin-bottom: 15px;*/
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .box-title h4 {
  font-size: 14px;
  margin-bottom: 10px;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .box-title h4 a {
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .box-title h5 {
  text-transform: uppercase;
  font-size: 14px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .box-title h6 {
  color: #3b5999;
  font-size: 12px;
  margin: 0 0 10px;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .social-icons {
  padding: 0;
  text-align: center;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .social-icons a {
  background: rgb(20, 6, 43);
  color: #fff;
  display: inline-block;
  font-size: 10px;
  height: 20px;
  margin-bottom: 6px;
  margin-right: 4px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 20px;
  border-radius: 50%;
  transition: 0.5s;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .social-icons a i {
  padding: 7px 0 0;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .social-icons a:hover i {
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .social-icons a:hover .fa-facebook {
  background: #3b5999;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .social-icons a:hover .fa-twitter {
  background: #3aa9e0;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .social-icons a:hover .fa-google-plus {
  background: #d04333;
}
.leadership-section .grid-style-13 .single-team-member .box-section .box-text .social-icons a:hover .fa-linkedin {
  background: #0080b1;
}
.leadership-section .grid-style-13 .single-team-member:hover .image-section img {
  transform: scale(1);
}
.leadership-section .grid-style-13 .single-team-member:hover .box-section {
  background-color: rgb(20, 6, 43);
}
.leadership-section .grid-style-13 .single-team-member:hover .box-section .box-text .box-title h4 a,
.leadership-section .grid-style-13 .single-team-member:hover .box-section .box-text .box-title h4,
.leadership-section .grid-style-13 .single-team-member:hover .box-section .box-text .box-title h5,
.leadership-section .grid-style-13 .single-team-member:hover .box-section .box-text .box-title h6 {
  color: #fff;
}
.leadership-section .grid-style-13 .single-team-member:hover .box-section .box-text .social-icons a {
  background: #fff;
  color: #000;
}
.leadership-section .grid-style-13 .single-team-member:hover .box-section:before {
  opacity: 1;
}
/* ------------------------------------
    32. Our Products Section
---------------------------------------*/
.our-products-section .single-product {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.our-products-section .single-product .product-image {
  position: relative;
  overflow: hidden;
}
.our-products-section .single-product .product-image:after,
.our-products-section .single-product .product-image:before {
  content: '';
  position: absolute;
  background: transparent;
  display: block;
  height: 50px;
  width: 50px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  z-index: 11;
}
.our-products-section .single-product .product-image:after {
  right: -10px;
  top: -10px;
  border-top: 5px solid;
  border-right: 5px solid;
  border-color: rgb(20, 6, 43);
}
.our-products-section .single-product .product-image:before {
  left: -10px;
  bottom: -10px;
  border-left: 5px solid;
  border-bottom: 5px solid;
  border-color: rgb(20, 6, 43);
}
.our-products-section .single-product .product-image .overly {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.our-products-section .single-product .product-tile {
  margin: 20px 0 5px;
}
.our-products-section .single-product .product-tile a {
  color: #323232;
  font-size: 18px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-weight: 600;
}
.our-products-section .single-product .product-tile a:hover {
  color: rgb(20, 6, 43);
}
.our-products-section .single-product .product-tile span {
  display: block;
}
.our-products-section .single-product .product-cart {
  border: 1px solid #898989;
  padding: 10px 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.our-products-section .single-product .product-cart a {
  font-weight: 600;
  color: rgb(20, 6, 43);
}
.our-products-section .single-product .product-cart a i {
  margin-right: 8px;
}
.our-products-section .single-product .product-details {
  background: #f1f1f1;
  padding-bottom: 25px;
}
.our-products-section .single-product .product-details .product-tile {
  margin-top: 0;
  padding-top: 20px;
}
.our-products-section .single-product .product-details .product-cart {
  padding: 6px 0;
  max-width: 150px;
  margin: 0 auto;
}
.our-products-section .single-product:hover .product-image {
  background: rgba(0, 0, 0, 0.6);
}
.our-products-section .single-product:hover .product-image:after {
  right: 15px;
  top: 15px;
}
.our-products-section .single-product:hover .product-image:before {
  left: 15px;
  bottom: 15px;
}
.our-products-section .single-product:hover .product-image .overly {
  opacity: 0.8;
  visibility: visible;
}
.our-products-section .single-product:hover .product-cart {
  background: #000000;
}
/* ------------------------------------
    33. Single Product Area
---------------------------------------*/
.single-product-page .single-product-area .inner-single-product-slider .inner {
  margin-bottom: 26px;
}
.single-product-page .single-product-area .single-product-nav {
  max-width: 570px;
  margin: 0 auto;
}
.single-product-page .single-product-area .single-product-nav .slick-slide {
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}
.single-product-page .single-product-area .single-product-nav .slick-slide:after {
  background: rgba(0, 0, 0, 0.7);
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.single-product-page .single-product-area .single-product-nav .images-slide-single img {
  width: 100%;
  height: 120px;
}
.single-product-page .single-product-area .single-product-nav .slick-current:after {
  opacity: 0;
}
.single-product-page .single-product-area .single-product-nav .slick-prev,
.single-product-page .single-product-area .single-product-nav .slick-next {
  width: 120px;
  height: 120px;
  background: rgb(20, 6, 43);
}
.single-product-page .single-product-area .single-product-nav .slick-prev {
  left: -137px;
}
.single-product-page .single-product-area .single-product-nav .slick-prev:before {
  content: "\f104";
}
.single-product-page .single-product-area .single-product-nav .slick-next {
  right: -137px;
}
.single-product-page .single-product-area .single-product-nav .slick-next:before {
  content: "\f105";
}
.single-product-page .single-product-area .single-product-nav .slick-prev::before,
.single-product-page .single-product-area .single-product-nav .slick-next::before {
  color: #323232;
  font-family: FontAwesome;
  opacity: 1;
  transition: all 0.5s ease 0s;
  font-size: 25px;
  line-height: 20px;
  font-weight: 700;
}
.single-product-page .single-product-area .single-product-nav .slick-prev:hover::before,
.single-product-page .single-product-area .single-product-nav .slick-prev:focus::before,
.single-product-page .single-product-area .single-product-nav .slick-next:hover::before,
.single-product-page .single-product-area .single-product-nav .slick-next:focus::before {
  opacity: 1;
  color: #fff;
}
.single-product-page .single-price-info h4 {
  font-size: 20px;
  margin: 0 0 15px;
}
.single-product-page .single-price-info a.primary-btn {
  font-size: 15px;
  color: #fff;
  max-width: 305px;
  display: block;
  text-align: center;
}
.single-product-page .single-price-info a.primary-btn:hover {
  background: #0d421e;
}
.single-product-page .single-price-info ul.single-product-icons {
  margin: 20px 0 10px;
}
.single-product-page .single-price-info ul.single-product-icons li {
  display: inline-block;
}
.single-product-page .single-price-info ul.single-product-icons li a {
  display: block;
  color: #757575;
  margin-right: 10px;
}
.single-product-page .single-price-info ul.single-product-icons li a:hover {
  color: rgb(20, 6, 43);
}
.single-product-page .single-price-info p.cat strong,
.single-product-page .single-price-info p.tag strong {
  font-weight: 400;
  color: rgb(20, 6, 43);
}
.single-product-page .single-price-info p.cat {
  margin: 0 0 10px;
}
.single-product-page .single-price-info p.tag {
  margin: 0;
}
.single-product-page .single-price-info span.single-price {
  color: rgb(20, 6, 43);
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 15px;
  display: block;
}
.single-product-page .recent-post-area {
  margin-top: 44px;
}
.single-product-page .recent-post-area ul li {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #ddd;
}
.single-product-page .recent-post-area ul li img {
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.single-product-page .recent-post-area ul li h4 {
  margin: 0;
  line-height: 20px;
}
.single-product-page .recent-post-area ul li h4 a {
  font-size: 15px;
  font-weight: 500;
  color: #323232;
}
.single-product-page .recent-post-area ul li span {
  font-size: 12px;
  margin-right: 12px;
}
.single-product-page .recent-post-area ul li span a {
  font-size: 12px;
  font-weight: 300;
  color: #757575;
}
.single-product-page .recent-post-area ul li span a i {
  font-size: 12px;
}
.single-product-page .recent-post-area ul li span i {
  font-size: 12px;
  margin-right: 4px;
}
.single-product-page .recent-post-area ul li:last-child {
  border-bottom: none;
}
.single-product-page .recent-post-area ul li:hover a {
  color: rgb(20, 6, 43);
}
.single-product-page .recent-post-area ul li:hover img {
  opacity: .5;
}
.single-product-page .product-description ul.nav-menus {
  border-bottom: 1px solid #e5e5e5;
  margin: 50px 0 26px;
  padding-bottom: 30px;
}
.single-product-page .product-description ul.nav-menus li {
  display: inline-block;
}
.single-product-page .product-description ul.nav-menus li a {
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #323232;
  margin-right: 30px;
}
.single-product-page .product-description ul.nav-menus li.active a,
.single-product-page .product-description ul.nav-menus li a:hover {
  color: rgb(20, 6, 43);
}
.single-product-page .our-products-section {
  margin-top: 40px;
}
.single-product-page .our-products-section .title-bar {
  overflow: hidden;
  clear: both;
  display: block;
}
/*....................................
    34. Shipping Area Start Here
....................................*/
.shipping-area .button-area ul li a {
  display: block;
  padding: 15px;
  background: #f8f8f8;
  color: #646464;
  font-size: 18px;
}
.shipping-area .button-area ul li.active a {
  background: rgb(20, 6, 43);
  color: #fff;
}
.shipping-area .product-list table {
  margin: 0 0 30px;
}
@media screen and (max-width: 767px) {
  .shipping-area .product-list table {
    width: 100%;
    margin: 0px;
  }
}
.shipping-area .product-list table tr {
  border: 1px solid #e7e7e7;
  padding: 25px;
  display: block;
  margin-bottom: -1px;
}
.shipping-area .product-list table tr td {
  padding-right: 52px;
}
@media screen and (max-width: 991px) {
  .shipping-area .product-list table tr td {
    padding-right: 10px;
  }
}
@media screen and (max-width: 767px) {
  .shipping-area .product-list table tr td {
    display: block;
    width: 100%;
    padding: 20px 0px;
  }
}
.shipping-area .product-list table tr td img {
  max-width: 80px;
}
.shipping-area .product-list table tr td img {
  width: 100%;
  display: block;
}
.shipping-area .product-list table tr td .des-pro {
  display: block;
  padding-right: 50px;
  width: 210px;
}
@media screen and (max-width: 991px) {
  .shipping-area .product-list table tr td .des-pro {
    width: auto;
  }
}
.shipping-area .product-list table tr td .des-pro h4 {
  font-weight: normal;
  margin: 0 0 10px;
  font-weight: 600;
}
.shipping-area .product-list table tr td .des-pro p {
  color: #646464;
  margin: 0;
}
.shipping-area .product-list table tr td strong {
  font-size: 20px;
  display: block;
  padding-right: 100px;
  font-weight: 600;
}
@media screen and (max-width: 991px) {
  .shipping-area .product-list table tr td strong {
    padding-right: 10px;
  }
}
.shipping-area .product-list table tr td .order-pro {
  position: relative;
  display: block;
  margin-right: 100px;
}
.shipping-area .product-list table tr td .order-pro input {
  width: 110px;
  height: 46px;
  box-shadow: none;
  border: 1px solid #ccc;
  text-align: center;
  padding-right: 10px;
  color: #888888;
  font-size: 18px;
}
.shipping-area .product-list table tr td .order-pro div {
  position: absolute;
  top: 12px;
  right: 0;
  z-index: 999;
  cursor: pointer;
}
.shipping-area .product-list table tr td .order-pro div.btn-plus {
  right: 40px;
}
.shipping-area .product-list table tr td .order-pro div.btn-minus {
  right: 20px;
}
.shipping-area .product-list table tr td .prize {
  color: rgb(20, 6, 43);
  font-size: 18px;
  font-weight: 500;
  padding-right: 50px;
}
.shipping-area .product-list table tr td i {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid #cccccc;
  text-align: center;
  line-height: 28px;
  font-size: 15px;
  cursor: pointer;
  color: #ccc;
}
.shipping-area .product-list table tr td i:hover {
  background: rgb(20, 6, 43);
  color: #fff;
}
.shipping-area .product-list .total span {
  font-size: 20px;
  padding-right: 10px;
}
.shipping-area .product-list .total strong {
  font-size: 28px;
  font-weight: 400;
}
.shipping-area .next-step {
  text-align: right;
}
.shipping-area .next-step a {
  padding: 10px 30px;
  border: 1px solid rgb(20, 6, 43);
  background: rgb(20, 6, 43) !important;
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
  background: transparent;
  margin-top: 25px;
  transition: all 0.3s ease 0s;
  display: inline-block;
}
.shipping-area .next-step a:hover {
  background: #323232 !important;
  color: #fff;
  border: 1px solid #323232 !important;
}
.shipping-area .form-area h3 {
  font-weight: 500;
  padding: 15px 15px;
  font-size: 22px;
}
.shipping-area .form-area form fieldset {
  margin: 0 0 15px;
}
.shipping-area .form-area form fieldset label {
  display: block;
  width: 100%;
  color: #333333;
  font-weight: 400;
  margin: 0 0 10px;
  font-size: 14px;
}
.shipping-area .form-area form fieldset input {
  display: block;
  width: 100%;
  margin: 0 0 10px;
  height: 40px;
  border-radius: 0;
  padding: 0 15px;
  border: 1px solid #ccc;
}
.shipping-area .form-area form fieldset select {
  display: block;
  width: 100%;
  margin: 0 0 10px;
  height: 40px;
  border-radius: 0;
  padding: 0 15px;
  color: #646464;
  font-size: 13px;
  border: 1px solid #ccc;
}
.shipping-area .order-list h4 {
  font-weight: 600;
  padding: 15px 0;
  font-size: 20px;
}
.shipping-area .order-list table {
  width: 100%;
}
.shipping-area .order-list table tr {
  width: 100%;
  display: block;
}
.shipping-area .order-list table tr th {
  font-weight: bold;
  width: 50%;
}
.shipping-area .order-list table tr td {
  border: 1px solid #dedede;
  padding: 15px 15px;
  font-size: 14px;
  font-weight: normal;
}
.shipping-area .order-list table tr td:first-child {
  width: 400px;
}
@media screen and (max-width: 480px) {
  .shipping-area .order-list table tr td:first-child {
    width: 65%;
  }
}
.shipping-area .order-list table tr td:last-child {
  width: 150px;
  text-align: center;
}
.shipping-area .order-list table .row-bold td {
  border: 1px solid #dedede;
  font-weight: 700;
}
.shipping-area .panel-group .panel {
  border-radius: 0;
  margin: 0;
}
.shipping-area .panel-group .panel-body {
  padding-left: 40px;
  padding-right: 100px;
}
.shipping-area .panel-group a .checkbox {
  margin: 0;
  padding: 10px 0;
}
.shipping-area .panel-group a .checkbox .cr {
  position: relative;
  display: inline-block;
  background: #cccccc;
  border-radius: 100%;
  float: left;
  margin-top: 0px;
  margin-right: .5em;
  width: 15px;
  height: 15px;
}
.shipping-area .panel-group a .checkbox label {
  margin: 0 !important;
  padding: 0 !important;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 700;
}
.shipping-area .panel-group a .checkbox label input[type="checkbox"] {
  display: none;
}
.shipping-area .panel-group a .checkbox label input[type="checkbox"] + .cr > .cr-icon {
  opacity: 1;
  display: block;
  color: rgb(20, 6, 43);
  width: 15px;
  height: 15px;
}
.shipping-area .panel-group a.collapsed .checkbox label input[type="checkbox"] + .cr > .cr-icon {
  opacity: 0;
  transition: all 0.3s ease-in;
  display: block;
  padding: 5px;
  color: #2962ff;
}
.shipping-area .panel-group .panel-default > .panel-heading {
  background: transparent;
}
.order-pro input[type=number]::-webkit-inner-spin-button,
.order-pro input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
.coupon-fields .input-text {
  padding: 5px 8px;
  width: 75%;
  margin-right: 10px;
  margin-bottom: 25px;
}
.coupon-fields .apply-coupon {
  background: rgb(20, 6, 43);
  border: none;
  color: #fff;
  padding: 6px 8px;
  border: 1px solid rgb(20, 6, 43);
  transition: all 0.3s ease 0s;
}
.coupon-fields .apply-coupon:hover {
  background: #323232 !important;
  border: 1px solid #323232;
  color: #fff;
}
/*....................................
    35. Check Out Css Start Here
....................................*/
.rs-check-out table {
  width: 100%;
}
.rs-check-out .check-out-box {
  padding: 0 0 40px 0;
}
.rs-check-out .check-out-box label {
  color: #757575;
  font-weight: 400;
}
.rs-check-out .check-out-box select,
.rs-check-out .check-out-box textarea,
.rs-check-out .check-out-box input {
  border-color: rgb(20, 6, 43);
  border-radius: 0;
}
.rs-check-out .check-out-box input {
  height: 40px;
  padding: 0 15px;
}
.rs-check-out .check-out-box select {
  width: 100%;
  min-height: 40px;
  padding: 0 15px;
}
.rs-check-out .check-out-box .checkbox input {
  height: auto;
  margin-top: 7px;
}
.rs-check-out .shipping-box .form-group input {
  height: 80px;
  border-color: rgb(20, 6, 43);
  border-radius: 0;
}
.rs-check-out .product-demo {
  width: 100%;
  display: inline-block;
  padding-bottom: 20px;
}
.rs-check-out .product-demo h5 {
  margin: 0;
  font-size: 15px;
  color: #505050;
  margin-left: 10px;
}
.rs-check-out .product-demo .product-image {
  width: 15%;
  float: left;
}
.rs-check-out .product-demo .product-name {
  width: 30%;
  float: left;
  transform: translateY(100%);
}
.rs-check-out .product-demo .product-quantity {
  width: 25%;
  float: left;
  text-align: center;
  transform: translateY(100%);
}
.rs-check-out .product-demo .product-ititial-price {
  width: 30%;
  float: left;
  text-align: center;
  transform: translateY(100%);
}
.rs-check-out .product-price table {
  border: 1px solid rgb(20, 6, 43);
  margin-bottom: 25px;
}
.rs-check-out .product-price table tr td {
  padding: 15px 25px;
  border-bottom: 1px solid #b2b2b2;
}
.rs-check-out .product-price table tr td input {
  margin-right: 5px;
}
.rs-check-out .product-price table tr td:last-child {
  text-align: right;
}
.rs-check-out .product-price table tr td.no-border {
  border: none;
}
.rs-check-out .rs-payment-system .payment-radio-btn1 {
  padding: 15px 25px;
  border: 1px solid rgb(20, 6, 43);
}
.rs-check-out .rs-payment-system .payment-radio-btn1 p {
  margin: 0;
  padding: 15px 0 15px 25px;
  font-size: 12px;
}
.rs-check-out .rs-payment-system .payment-radio-btn2 {
  padding: 15px 25px;
  border: 1px solid rgb(20, 6, 43);
  border-top: none;
}
.rs-check-out .rs-payment-system input {
  margin-right: 10px;
}
.rs-check-out .rs-payment-system input.btn-send {
  width: 100%;
  margin-top: 25px;
  background-color: rgb(20, 6, 43);
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  height: 50px;
  line-height: 50px;
  text-align: c center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
}
.rs-check-out .rs-payment-system input.btn-send:hover {
  color: rgb(20, 6, 43);
  background: #323232;
}
/*-----------------------------------
   36. Why Choose Us
-----------------------------------*/
.why-choose-us {
  padding-bottom: 94px;
}
.why-choose-us .services-responsibiity .sec-title {
  margin-bottom: 10px;
}
.why-choose-us .services-responsibiity .sec-title h3 {
  margin-bottom: 18px;
}
.why-choose-us .services-responsibiity .sec-title p {
  margin: 0;
}
.why-choose-us .services-responsibiity .services-item {
  z-index: 10;
  overflow: hidden;
  width: 100%;
}
.why-choose-us .services-responsibiity .services-item i {
  font-size: 20px;
}
.why-choose-us .services-responsibiity .services-item .service-mid-icon {
  width: 50px;
  height: 50px;
  float: left;
  margin-bottom: 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  border: 1px solid rgb(20, 6, 43);
  margin-right: 15px;
}
.why-choose-us .services-responsibiity .services-item .services-desc {
  overflow: hidden;
}
.why-choose-us .services-responsibiity .services-item .services-desc .services-title {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 6px;
  font-weight: 600;
}
.why-choose-us .services-responsibiity .services-item .services-desc .services-title a {
  color: rgb(20, 6, 43);
}
.why-choose-us .services-responsibiity .services-item .services-desc p {
  margin-bottom: 18px;
}
.why-choose-us .services-responsibiity .services-item:last-child .services-desc p {
  margin-bottom: 0;
}
.why-choose-us .services-responsibiity .services-item:hover .service-mid-icon {
  background: rgb(20, 6, 43);
}
.why-choose-us .services-responsibiity .services-item:hover .service-mid-icon a {
  color: #fff;
}
.choose-Us .choose-Us-title h3 {
  font-size: 30px;
  margin-bottom: 26px;
}
.choose-Us .choose-Us-content {
  margin-right: 15px;
}
.choose-Us .choose-Us-content .main-goal h5 {
  font-weight: 700;
  color: #555;
}
.choose-Us .choose-Us-content .main-goal p {
  padding-bottom: 5px;
  margin: 0;
  color: #000;
  position: relative;
  padding-left: 16px;
}
.choose-Us .choose-Us-content .main-goal p i {
  color: rgb(20, 6, 43);
  font-weight: 500;
  position: absolute;
  left: 0;
  top: 4px;
}
.choose-Us .choose-Us-content .main-goal p.mb-15 {
  margin-bottom: 15px;
}
.choose-Us .choose-Us-content .main-goal p:last-child {
  padding-bottom: 0;
}
.rs-skills .sec-title {
  margin-bottom: 0;
}
.rs-skills .sec-title h3 {
  margin-bottom: 26px;
}
.rs-skills .rs-team-details {
  padding: 0px !important;
}
.rs-skills .skillbar-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}
.rs-skills .rs-team-details .cl-skill-bar .skillbar .skillbar-bar {
  height: 25px;
}
.rs-skills .rs-team-details .cl-skill-bar .skillbar .skill-bar-percent {
  height: 25px;
  line-height: 25px;
  font-size: 12px;
}
.rs-skills .rs-team-details .cl-skill-bar .skillbar .skillbar-title {
  height: 25px;
  line-height: 25px;
}
.rs-skills .cl-skill-bar .skillbar {
  background: #f0f0f0;
  border-radius: 3px;
  display: inline-block;
  height: 25px;
  margin-top: 0px;
  margin-bottom: 23px;
  position: relative;
  width: 100%;
}
.rs-skills .cl-skill-bar .skillbar .skillbar-title {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  height: 30px;
  left: 0;
  line-height: 30px;
  padding: 0 20px;
  position: absolute;
  top: 0;
  width: 150px;
  background: #3b8a3e;
  text-transform: uppercase;
}
.rs-skills .cl-skill-bar .skillbar .skillbar-bar {
  background: rgb(20, 6, 43);
  border-radius: 3px;
  display: inline-block;
  height: 30px;
  width: 0;
  margin-bottom: 0;
}
.rs-skills .cl-skill-bar .skillbar .skill-bar-percent {
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  left: 50%;
  color: #fff;
  font-weight: 500;
  top: 0;
}
.rs-skills .panel-heading {
  background: #4caf50;
  border-radius: 0;
  border: none;
}
.rs-skills .panel-heading .panel-title a {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  display: block;
}
.rs-skills .panel-default {
  border: none;
  box-shadow: none;
}
.rs-skills .rs-skills .sec-title p {
  color: #555;
  margin: 20px 0 0;
}
.rs-skills .cl-skill-bar {
  margin-top: -5px;
}
/*-----------------------------------
    37. How We Work
-----------------------------------*/
.how-we-work {
  text-align: center;
}
.how-we-work .work-sec-title h2 {
  font-size: 40px;
  letter-spacing: 2px;
  font-weight: 700;
}
.how-we-work .work-sec-title p {
  font-weight: 300;
  font-size: 18px;
  color: #505050;
  margin-bottom: 48px;
}
.how-we-work .work-column {
  position: relative;
  overflow: hidden;
  padding: 10px;
}
.how-we-work .work-column .common-box {
  overflow: hidden;
}
.how-we-work .work-column .work-gallery-caption h4 {
  margin-bottom: 10px;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  letter-spacing: 2px;
  line-height: 20px;
}
.how-we-work .work-column .work-gallery-caption p {
  color: #555;
  font-size: 14px;
  margin-top: 0;
}
.how-we-work .work-column img {
  transition: all 0.3s;
}
.how-we-work .work-column:hover img {
  transform: scale(1.1);
}
.how-we-work .work-column:hover .work-gallery-caption h4 a {
  color: rgb(20, 6, 43);
}
.how-we-work.defult-style .work-column .work-gallery-caption h4 {
  letter-spacing: 0;
  font-weight: 600;
}
.how-we-work.defult-style .work-column .work-gallery-caption h4 a {
  color: #323232;
}
.how-we-work.defult-style .work-column .work-gallery-caption h4 a:hover {
  color: rgb(20, 6, 43);
}
.how-we-work.defult-style .work-column:hover .work-gallery-caption h4 a {
  color: rgb(20, 6, 43);
}
/*-----------------------------------
    38. Support Section Css
-----------------------------------*/
.support-section {
  position: relative;
  color: #fff;
}
.support-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
}
.support-section .need-query h3 {
  margin: 0 0 34px;
}
.support-section .over-layre:after {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.support-section h3 {
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 30px;
  line-height: 28px;
  line-height: 31px;
  letter-spacing: 1px;
  font-weight: 700;
}
.support-section .form-inner {
  padding-right: 40px;
}
.support-section .form-inner form#quick-form {
  margin-top: -65px;
  padding: 40px 45px 50px 45px;
  background: rgb(20, 6, 43);
  position: relative;
}
.support-section .form-inner form#quick-form:after {
  background: rgb(20, 6, 43);
  transform: skewY(-174deg);
  content: "";
  height: 70px;
  left: 0;
  position: absolute;
  top: -35px;
  width: 100%;
}
.support-section .form-inner form#quick-form .need-query,
.support-section .form-inner form#quick-form .quick-form {
  position: relative;
  z-index: 111;
}
.support-section .form-inner form#quick-form p {
  line-height: 24px;
}
.support-section button,
.support-section input,
.support-section select,
.support-section textarea {
  width: 100%;
  background: transparent;
  border: 1px solid #0d421e;
  padding: 13px 10px;
  outline: unset;
  margin-bottom: 30px;
  font-weight: 500;
  padding-left: 20px;
}
.support-section ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff !important;
  opacity: .7 !important;
}
.support-section ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff !important;
  opacity: .7 !important;
}
.support-section :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff !important;
  opacity: .7 !important;
}
.support-section :-moz-placeholder {
  /* Firefox 18- */
  color: #fff !important;
  opacity: .7 !important;
}
.support-section textarea {
  height: 104px;
  padding: 20px;
}
.support-section input.submit {
  background: #fff;
  color: rgb(20, 6, 43);
  transition: .25s;
  width: 190px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  margin: 10px auto 0;
  padding: 0;
  display: block;
  border-radius: 30px;
}
.support-section input.submit:hover {
  background: transparent;
  color: #fff;
}
/*-------------------------------------
    39. Customer Review Css
-------------------------------------*/
.rs-customer3 {
  background: #fff;
}
.rs-customer3 .customer-carousel .owl-dots {
  margin-top: 45px;
  text-align: center;
}
.rs-customer3 .customer-carousel .owl-dots .owl-dot {
  border: 1px solid rgb(20, 6, 43);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background: transparent;
  display: inline-block;
  margin-right: 10px;
}
.rs-customer3 .customer-carousel .owl-dots .owl-dot:hover {
  background: rgb(20, 6, 43);
}
.rs-customer3 .customer-carousel .owl-dots .active {
  background: rgb(20, 6, 43);
}
.rs-customer3 .customer-item {
  text-align: center;
  background: #f0f0f0;
  padding: 30px 20px;
  border-radius: 3px;
  transition: all 0.3s ease 0s;
}
.rs-customer3 .customer-item .item-img {
  margin-bottom: 25px;
}
.rs-customer3 .customer-item .item-img img {
  padding: 5px;
  border: 2px solid rgb(20, 6, 43);
  width: 86px;
  border-radius: 50%;
  margin: 0 auto;
}
.rs-customer3 .customer-item .item-details {
  font-style: italic;
}
.rs-customer3 .customer-item .item-details p {
  margin: 0 0 15px;
}
.rs-customer3 .customer-item .item-author .item-name {
  font-size: 16px;
  line-height: 100%;
  color: #101010;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
}
.rs-customer3 .customer-item .item-author .item-designation {
  font-size: 14px;
  line-height: 100%;
  font-weight: normal;
  color: #505050;
  display: block;
}
.rs-customer3 .customer-item:hover {
  background: rgb(20, 6, 43);
}
.rs-customer3 .customer-item:hover .item-img img {
  border-color: #fff;
}
.rs-customer3 .customer-item:hover .item-details {
  color: #fff;
}
.rs-customer3 .customer-item:hover .item-author .item-name {
  color: #fff;
}
.rs-customer3 .customer-item:hover .item-author .item-designation {
  color: #fff;
}
.rs-defult-customer .owl-stage-outer {
  padding-top: 38px;
}
.rs-defult-customer .customer-item {
  padding-top: 0;
  background: #f4f7fc;
}
.rs-defult-customer .customer-item .item-author .item-name {
  color: rgb(20, 6, 43);
}
.rs-defult-customer .customer-item .item-author .item-designation {
  color: #757575;
}
.rs-defult-customer .customer-item .item-details {
  font-style: normal;
  position: relative;
  padding-top: 10px;
  margin-top: 10px;
}
.rs-defult-customer .customer-item .item-details p {
  margin: 0 0 15px;
}
.rs-defult-customer .customer-item .item-details:after {
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  content: "\f10d";
  font-family: "FontAwesome";
  font-size: 20px;
  position: absolute;
  color: rgb(20, 6, 43);
  font-weight: 700;
  transition: 0.4s;
}
.rs-defult-customer .customer-item .item-img {
  margin-bottom: 0;
}
.rs-defult-customer .customer-item .item-img img {
  top: -34px;
  position: relative;
  border: none;
  padding: 0;
  box-shadow: 0 0 8px #444;
  width: 70px;
}
.rs-defult-customer .customer-item:hover .item-details:after {
  color: #fff;
}
/* ------------------------------------
    40. Preloader Css
---------------------------------------*/
#loading {
  background-color: rgb(20, 6, 43);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  z-index: 999999;
}
#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}
#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin-top: -30px;
  margin-left: -30px;
  -webkit-animation: loading-center-absolute 1s infinite;
  animation: loading-center-absolute 1s infinite;
}
.object {
  width: 20px;
  height: 20px;
  background-color: #FFF;
  float: left;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  margin-right: 20px;
  margin-bottom: 20px;
}
.object:nth-child(2n+0) {
  margin-right: 0px;
}
#object_one {
  -webkit-animation: object_one 1s infinite;
  animation: object_one 1s infinite;
}
#object_two {
  -webkit-animation: object_two 1s infinite;
  animation: object_two 1s infinite;
}
#object_three {
  -webkit-animation: object_three 1s infinite;
  animation: object_three 1s infinite;
}
#object_four {
  -webkit-animation: object_four 1s infinite;
  animation: object_four 1s infinite;
}
@-webkit-keyframes loading-center-absolute {
  100% {
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading-center-absolute {
  100% {
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes object_one {
  50% {
    -ms-transform: translate(20px, 20px);
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }
}
@keyframes object_one {
  50% {
    -ms-transform: translate(20px, 20px);
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }
}
@-webkit-keyframes object_two {
  50% {
    -ms-transform: translate(-20px, 20px);
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
}
@keyframes object_two {
  50% {
    -ms-transform: translate(-20px, 20px);
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
}
@-webkit-keyframes object_three {
  50% {
    -ms-transform: translate(20px, -20px);
    -webkit-transform: translate(20px, -20px);
    transform: translate(20px, -20px);
  }
}
@keyframes object_three {
  50% {
    -ms-transform: translate(20px, -20px);
    -webkit-transform: translate(20px, -20px);
    transform: translate(20px, -20px);
  }
}
@-webkit-keyframes object_four {
  50% {
    -ms-transform: translate(-20px, -20px);
    -webkit-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
  }
}
@keyframes object_four {
  50% {
    -ms-transform: translate(-20px, -20px);
    -webkit-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
  }
}
@media only screen and (min-width: 991px) {
  .mr-10 {
    margin-right: 10px;
  }
  .mr-5 {
    margin-right: 5px;
  }
  .ml-10 {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 991px) {
  body #rs-header .menu-area .rs-menu ul li {
    background: rgb(20, 6, 43);
  }
  .nav-menu > li > a {
    border-bottom: 1px solid #0d421e;
  }
  #default-header.transparent-header .menu-sticky li.current-menu-item > a {
    color: #323232 !important;
  }
  #rs-header3 .menu-area,
  #default-header .menu-area .rs-menu ul.nav-menu li a {
    background: rgb(20, 6, 43);
  }
}
@media only screen and (max-width: 767px) {
  .toolbar-area {
    background: #0d421e !important;
  }
  #default-header .menu-area .navbar .navbar-toggle .icon-bar {
    background: rgb(20, 6, 43) !important;
  }
  #default-header .menu-area .navbar .navbar-toggle .icon-bar:hover {
    background: #0d421e !important;
  }
}

ul.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.images > li {
  position: relative;
  padding-top: 66%;
}

ul.images > li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 567px) {
  ul.images {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: #14062b !important;
}

.mediacells {
  width: 100%;
  height: 650px;
}

.card {
  /* position: relative; */
  /* display: block; */
  height: fit-content;  
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  border: 0px;
  vertical-align: middle;
}

.card__image {      
  width: 100%;
  height:fit-content;
}

.card__overlay {
  position: absolute;
  bottom: -85pt;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: calc(var(--curve) * 1px);    
  background-color: whitesmoke;      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;    
  background-color: whitesmoke;
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}

.card__arc path {
  fill: whitesmoke;
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}       

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
}

.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #14062b;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";  
  font-size: .8em; 
  color: #14062b;  
}

.card__status {
  font-size: .8em;
  color: #14062b;
}

.card__description {
  height: 100px;
  padding: 0 2em 2em;
  margin: 0;
  color: #14062b;
  font-family: "MockFlowFont";   
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.btn_hover :hover{
  background-color: #14062b;
  color: whitesmoke;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create two equal columns that sits next to each other */
.column {
  flex: 33.33%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
}

.tickers{
  background: rgb(20, 6, 43);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  font-size: .8rem;
}
.tickers-text{
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.text1 span{
  margin-right: 2rem;
}
.text2 span:not(:last-child) {
  margin-right: 2rem;
}
.text2 span:last-child {
  margin-right: 1rem;
}

#foot_img {
  width: calc(100vw*0.2) 
}
#foot_text {
  margin: 0;
  font-size: calc(100vw*0.03);
}